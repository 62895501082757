import React, { FC, Fragment } from "react";
import Confirm from "./confirm";

import "./index.less";

const GlbModule: FC = () => {
  return (
    <Fragment>
      <Confirm />
    </Fragment>
  );
};

export default GlbModule;
