import { AxiosRequestConfig } from "axios";
import reqService from "./request";

export abstract class BaseAPI {
  private async sendRequest(config: AxiosRequestConfig, pass?: boolean) {
    return await reqService(config, pass);
  }
  // get请求方法
  protected async get<T = any>(
    url: string,
    params?: T,
    config?: AxiosRequestConfig,
    pass: boolean = false
  ) {
    return await this.sendRequest(
      {
        params: params || {},
        ...config,
        method: "get",
        url: `${url}`,
      },
      pass
    );
  }

  // post请求方法
  protected async post<T = any>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
    pass: boolean = false
  ) {
    return await this.sendRequest(
      {
        data: data || {},
        ...config,
        method: "post",
        url: `${url}`,
        headers: config?.headers || { "Content-Type": "application/json" },
      },
      pass
    );
  }

  // delete请求方法
  protected async delete<T = any>(
    url: string,
    params?: T,
    config?: AxiosRequestConfig,
    pass: boolean = false
  ) {
    return await this.sendRequest(
      {
        params: params || {},
        ...config,
        method: "delete",
        url: `${url}`,
      },
      pass
    );
  }

  // put请求方法
  protected async put<T = any>(
    url: string,
    data?: T,
    config?: AxiosRequestConfig,
    pass: boolean = false
  ) {
    return await this.sendRequest(
      {
        data: data || {},
        ...config,
        method: "put",
        url: `${url}`,
        headers: { "Content-Type": "application/json" },
      },
      pass
    );
  }
}
