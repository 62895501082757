import { IRouteObject } from "@/common/interface";
import Dashboard from "@/pages/dashboard/index";
import User from "@/pages/user/index";
import UserNew from "@/pages/user/new";
import Role from "@/pages/role/index";
import RoleNew from "@/pages/role/new";
import RoleUpdate from "@/pages/role/update";
import Subscriber from "@/pages/subscriber/index";
import SubscriberNew from "@/pages/subscriber/new";
import Subscription from "@/pages/subscription/index";
import SubscriptionNew from "@/pages/subscription/new";
import Meeting from "@/pages/meeting/index";
import ManageMeetings from "@/pages/meeting/manageMeetings";
import GeoBlocking from "@/pages/geoBlocking/index";
import Venues from "@/pages/venues/index";
import SwitchOver from "@/pages/switchOver/index";
import LiveMonitoring from "@/pages/liveMonitoring/index";
import ManageBatchJob from "@/pages/manageBatchJob/index";
import CustomerService from "@/pages/customerService/index";
import CustomerServiceValid from "@/pages/customerServiceValid/index";
import CustomerServiceTerminated from "@/pages/customerServiceTerminated/index";
import Finance from "@/pages/finance/index";
import FinanceSuccessfulSubscriptions from "@/pages/financeSuccessfulSubscriptions/index";
import FinanceRenewedSubscriptions from "@/pages/financeRenewedSubscriptions/index";
import AuditLogsUsers from "@/pages/auditLogsUsers/index";
import AuditLogsUsersList from "@/pages/auditLogsUsersList/index";
import AuditLogsUsersHistory from "@/pages/auditLogsUsersHistory/index";
import AuditLogRoles from "@/pages/auditLogRoles/index";
import AuditLogsSubscriptionRates from "@/pages/auditLogsSubscriptionRates/index";
import AuditLogsSubscriptions from "@/pages/auditLogsSubscriptions/index";
import AuditLogsBatchEngine from "@/pages/auditLogsBatchEngine/index";
import VenuesConfig from "@/pages/configurationManagement/venuesConfig/index";
import VenuesConfigNew from "@/pages/configurationManagement/venuesConfig/new";
import ConfigurationList from "@/pages/configurationManagement/configurationList/index";
import ConfigurationListNew from "@/pages/configurationManagement/configurationList/new";
import ModuleManagement from "@/pages/configurationManagement/moduleManagement/index";
import ModuleManagementNew from "@/pages/configurationManagement/moduleManagement/new";
import Ldap from "@/pages/configurationManagement/ldap/index";
import IpWhitelist from "@/pages/configurationManagement/ipWhitelist/index";
import AuthenticationManagement from "@/pages/authenticationManagement/index";
import { Suspense } from "react";
import LoadingPlx from "@/common/LoadingPlx/LoadingPlx";
import RouterAuth from "./routerAuth";
import Welcome from "@/pages/welcome/index";

export const commonRouter: IRouteObject = {
  icon: "icon-zhuye",
  index: true,
  label: "Home",
  code: "HOME",
  element: (
    <RouterAuth>
      <Welcome />
    </RouterAuth>
  ),
};

export const routerList: IRouteObject[] = [
  {
    icon: "icon-yibiaopan",
    label: "Dashboard",
    code: "DASHBOARD",
    path: "dashboard",
    element: (
      <RouterAuth>
        <Suspense fallback={<LoadingPlx />}>
          <Dashboard />
        </Suspense>
      </RouterAuth>
    ),
  },
  {
    icon: "icon-user",
    label: "Users",
    path: "users",
    code: "USERS",
    children: [
      {
        index: true,
        element: (
          <RouterAuth>
            <Suspense fallback={<LoadingPlx />}>
              <User />
            </Suspense>
          </RouterAuth>
        ),
      },
      {
        path: "new",
        element: (
          <RouterAuth>
            <Suspense fallback={<LoadingPlx />}>
              <UserNew />
            </Suspense>
          </RouterAuth>
        ),
      },
      {
        path: ":id/edit",
        element: (
          <RouterAuth>
            <Suspense fallback={<LoadingPlx />}>
              <UserNew />
            </Suspense>
          </RouterAuth>
        ),
      },
    ],
  },
  {
    path: "roles",
    icon: "icon-yuechi",
    label: "Roles",
    code: "ROLES",
    children: [
      {
        index: true,
        element: (
          <RouterAuth>
            <Suspense fallback={<LoadingPlx />}>
              <Role />
            </Suspense>
          </RouterAuth>
        ),
      },
      {
        path: "new",
        element: (
          <RouterAuth>
            <Suspense fallback={<LoadingPlx />}>
              <RoleNew />
            </Suspense>
          </RouterAuth>
        ),
      },
      {
        path: ":id/edit",
        element: (
          <RouterAuth>
            <Suspense fallback={<LoadingPlx />}>
              <RoleUpdate />
            </Suspense>
          </RouterAuth>
        ),
      },
    ],
  },
  {
    path: "subscribers",
    icon: "icon-yonghuguanli",
    label: "Subscriber Management",
    code: "SUBSCRIBERS",
    children: [
      {
        index: true,
        element: (
          <RouterAuth>
            <Suspense fallback={<LoadingPlx />}>
              <Subscriber />
            </Suspense>
          </RouterAuth>
        ),
      },
      {
        path: "new",
        element: (
          <RouterAuth>
            <Suspense fallback={<LoadingPlx />}>
              <SubscriberNew />
            </Suspense>
          </RouterAuth>
        ),
      },
      {
        path: ":id/edit",
        element: (
          <RouterAuth>
            <Suspense fallback={<LoadingPlx />}>
              <SubscriberNew />
            </Suspense>
          </RouterAuth>
        ),
      },
    ],
  },
  {
    path: "subscription_rates",
    icon: "icon-yonghuguanli",
    label: "Subscription Management",
    code: "SUBSCRIPTIONRATE",
    children: [
      {
        index: true,
        element: (
          <RouterAuth>
            <Suspense fallback={<LoadingPlx />}>
              <Subscription />
            </Suspense>
          </RouterAuth>
        ),
      },
      {
        path: "new",
        element: (
          <RouterAuth>
            <Suspense fallback={<LoadingPlx />}>
              <SubscriptionNew />
            </Suspense>
          </RouterAuth>
        ),
      },
      {
        path: ":id/edit",
        element: (
          <RouterAuth>
            <Suspense fallback={<LoadingPlx />}>
              <SubscriptionNew />
            </Suspense>
          </RouterAuth>
        ),
      },
    ],
  },
  {
    icon: "icon-tiaozheng",
    label: "Live Services",
    path: "provisioning",
    code: "LIVE",
    children: [
      {
        path: "meeting",
        label: "Meetings",
        code: "MEETINGS",
        children: [
          {
            index: true,
            element: (
              <RouterAuth>
                <Suspense fallback={<LoadingPlx />}>
                  <Meeting />
                </Suspense>
              </RouterAuth>
            ),
          },
          {
            path: "manage-meetings",
            element: (
              <RouterAuth>
                <Suspense fallback={<LoadingPlx />}>
                  <ManageMeetings />
                </Suspense>
              </RouterAuth>
            ),
          },
        ],
      },
      {
        path: "geoblock",
        label: "Geo Blocking",
        code: "GEOBLOCKING",
        element: (
          <RouterAuth>
            <Suspense fallback={<LoadingPlx />}>
              <GeoBlocking />
            </Suspense>
          </RouterAuth>
        ),
      },
      {
        path: "venue",
        label: "Venues",
        code: "VENUES",
        element: (
          <RouterAuth>
            <Suspense fallback={<LoadingPlx />}>
              <Venues />
            </Suspense>
          </RouterAuth>
        ),
      },
      {
        path: "failover",
        label: "Switch Over",
        code: "SWITCHOVER",
        element: (
          <RouterAuth>
            <Suspense fallback={<LoadingPlx />}>
              <SwitchOver />
            </Suspense>
          </RouterAuth>
        ),
      },
      {
        path: "livemonitoring",
        label: "Live Monitoring",
        code: "LIVEMONITORING",
        element: (
          <RouterAuth>
            <Suspense fallback={<LoadingPlx />}>
              <LiveMonitoring />
            </Suspense>
          </RouterAuth>
        ),
      },
    ],
  },
  {
    path: "batchengine",
    icon: "icon-naozhong",
    label: "Batch Job Engine",
    code: "BATCHENGINE",
    element: (
      <RouterAuth>
        <Suspense fallback={<LoadingPlx />}>
          <ManageBatchJob />
        </Suspense>
      </RouterAuth>
    ),
  },
  {
    icon: "icon-tiaozheng",
    label: "Reports",
    code: "REPORTS",
    path: "reports",
    children: [
      {
        path: "customer_service",
        code: "CUSTOMER_SERVICE",
        label: "Customer Service",
        children: [
          {
            index: true,
            element: (
              <RouterAuth>
                <Suspense fallback={<LoadingPlx />}>
                  <CustomerService />
                </Suspense>
              </RouterAuth>
            ),
          },
          {
            path: "valid",
            element: (
              <RouterAuth>
                <Suspense fallback={<LoadingPlx />}>
                  <CustomerServiceValid />
                </Suspense>
              </RouterAuth>
            ),
          },
          {
            path: "terminated",
            element: (
              <RouterAuth>
                <Suspense fallback={<LoadingPlx />}>
                  <CustomerServiceTerminated />
                </Suspense>
              </RouterAuth>
            ),
          },
        ],
      },
      {
        path: "finance",
        label: "Finance",
        code: "FINANCE",
        children: [
          {
            index: true,
            element: (
              <RouterAuth>
                <Suspense fallback={<LoadingPlx />}>
                  <Finance />
                </Suspense>
              </RouterAuth>
            ),
          },
          {
            path: "successful",
            element: (
              <RouterAuth>
                <Suspense fallback={<LoadingPlx />}>
                  <FinanceSuccessfulSubscriptions />
                </Suspense>
              </RouterAuth>
            ),
          },
          {
            path: "renewed",
            element: (
              <RouterAuth>
                <Suspense fallback={<LoadingPlx />}>
                  <FinanceRenewedSubscriptions />
                </Suspense>
              </RouterAuth>
            ),
          },
        ],
      },
    ],
  },
  {
    icon: "icon-tiaozheng",
    label: "Audit Logs",
    code: "AUDITLOGS",
    path: "audits",
    children: [
      {
        path: "users",
        label: "Users",
        children: [
          {
            index: true,
            element: (
              <RouterAuth>
                <Suspense fallback={<LoadingPlx />}>
                  <AuditLogsUsers />
                </Suspense>
              </RouterAuth>
            ),
          },
          {
            path: "authorized",
            element: (
              <RouterAuth>
                <Suspense fallback={<LoadingPlx />}>
                  <AuditLogsUsersList />
                </Suspense>
              </RouterAuth>
            ),
          },
          {
            path: "record_history",
            element: (
              <RouterAuth>
                <Suspense fallback={<LoadingPlx />}>
                  <AuditLogsUsersHistory />
                </Suspense>
              </RouterAuth>
            ),
          },
        ],
      },
      {
        path: "roles",
        label: "Roles",
        element: (
          <RouterAuth>
            <Suspense fallback={<LoadingPlx />}>
              <AuditLogRoles />
            </Suspense>
          </RouterAuth>
        ),
      },
      {
        path: "subscription-rates",
        label: "Subscription Rates",
        element: (
          <RouterAuth>
            <Suspense fallback={<LoadingPlx />}>
              <AuditLogsSubscriptionRates />
            </Suspense>
          </RouterAuth>
        ),
      },
      {
        path: "subscriptions",
        label: "Subscriptions",
        element: (
          <RouterAuth>
            <Suspense fallback={<LoadingPlx />}>
              <AuditLogsSubscriptions />
            </Suspense>
          </RouterAuth>
        ),
      },
      {
        path: "manage-batch-log",
        label: "Batch Engine Logs",
        element: (
          <RouterAuth>
            <Suspense fallback={<LoadingPlx />}>
              <AuditLogsBatchEngine />
            </Suspense>
          </RouterAuth>
        ),
      },
    ],
  },
  {
    icon: "icon-tiaozheng",
    label: "Configuration Management",
    path: "config",
    code: "CONFIGURATION",
    children: [
      {
        path: "venues",
        code: "CONFIG_VENUES",
        label: "Venues",
        children: [
          {
            index: true,
            element: (
              <RouterAuth>
                <Suspense fallback={<LoadingPlx />}>
                  <VenuesConfig />
                </Suspense>
              </RouterAuth>
            ),
          },
          {
            path: "new",
            element: (
              <RouterAuth>
                <Suspense fallback={<LoadingPlx />}>
                  <VenuesConfigNew />
                </Suspense>
              </RouterAuth>
            ),
          },
          {
            path: ":id/edit",
            element: (
              <RouterAuth>
                <Suspense fallback={<LoadingPlx />}>
                  <VenuesConfigNew />
                </Suspense>
              </RouterAuth>
            ),
          },
        ],
      },
      {
        path: "config-management",
        label: "Config",
        code: "CONFIG_CONFIG",
        children: [
          {
            index: true,
            element: (
              <RouterAuth>
                <Suspense fallback={<LoadingPlx />}>
                  <ConfigurationList />
                </Suspense>
              </RouterAuth>
            ),
          },
          {
            path: "new",
            element: (
              <RouterAuth>
                <Suspense fallback={<LoadingPlx />}>
                  <ConfigurationListNew />
                </Suspense>
              </RouterAuth>
            ),
          },
          {
            path: ":id/edit",
            element: (
              <RouterAuth>
                <Suspense fallback={<LoadingPlx />}>
                  <ConfigurationListNew />
                </Suspense>
              </RouterAuth>
            ),
          },
        ],
      },
      {
        path: "module",
        label: "Module",
        code: "CONFIG_MODULE",
        children: [
          {
            index: true,
            element: (
              <RouterAuth>
                <Suspense fallback={<LoadingPlx />}>
                  <ModuleManagement />
                </Suspense>
              </RouterAuth>
            ),
          },
          {
            path: "new",
            element: (
              <RouterAuth>
                <Suspense fallback={<LoadingPlx />}>
                  <ModuleManagementNew />
                </Suspense>
              </RouterAuth>
            ),
          },
          {
            path: ":id/edit",
            element: (
              <RouterAuth>
                <Suspense fallback={<LoadingPlx />}>
                  <ModuleManagementNew />
                </Suspense>
              </RouterAuth>
            ),
          },
        ],
      },
      {
        path: "ldap",
        label: "Ldap",
        code: "CONFIG_LDAP",
        element: (
          <RouterAuth>
            <Suspense fallback={<LoadingPlx />}>
              <Ldap />
            </Suspense>
          </RouterAuth>
        ),
      },
      {
        path: "ip-whitelist",
        label: "IP Whitelist",
        code: "CONFIG_IPWHITELIST",
        element: (
          <RouterAuth>
            <Suspense fallback={<LoadingPlx />}>
              <IpWhitelist />
            </Suspense>
          </RouterAuth>
        ),
      },
    ],
  },
  {
    path: "register2fa",
    code: "AUTHENTICATION_MANAGEMENT",
    element: (
      <RouterAuth>
        <AuthenticationManagement />
      </RouterAuth>
    ),
  },
];
