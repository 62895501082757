import { Layout, Menu } from "antd";
import { FC, useCallback, useMemo, useRef } from "react";
import logo from "@/assets/logo.png";
import useMenu from "@/hooks/useMenus";
import { useLocation, useNavigate } from "react-router-dom";
import "./index.less";
import { ICallback } from "@/common/interface";

interface SilderProps {
  collapsed: boolean;
  setCollapsed: ICallback<boolean>;
}

const Sider: FC<SilderProps> = ({ collapsed, setCollapsed }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const menus = useMenu();
  const { current: _setCollapsed } = useRef(setCollapsed);

  // const [hovered, setHovered] = useState(false);

  const defaultRoute = useMemo(() => {
    if (menus) {
      return menus[0]?.children?.[0]?.key || menus[0]?.key;
    }
    return "/admin";
  }, [menus]);
  const openKeys = useMemo(() => {
    const paths = location.pathname?.split("/").filter((it) => it) || [];
    const newKeys: string[] = [];
    paths.reduce((prev, cur) => {
      newKeys.push(`${prev}/${cur}`);
      return `${prev}/${cur}`;
    }, "");
    return newKeys || defaultRoute;
  }, [location, defaultRoute]);

  const selectedKeysMemo = useMemo(() => {
    const paths = location.pathname?.split("/").filter((it) => it) || [];
    const newKeys: string[] = [];
    paths.reduce((prev, cur) => {
      newKeys.push(`${prev}/${cur}`);
      return `${prev}/${cur}`;
    }, "");
    const defRoutes = location?.pathname !== "/" ? location?.pathname : "";

    return defRoutes ? newKeys : [menus[0]?.key];
  }, [location, menus]);

  const goPages = useCallback(
    (key: string) => {
      if (key === location.pathname) return;
      navigate(key);
    },
    [navigate, location]
  );

  const handleMouseEnter = useCallback(() => {
    if (collapsed) {
      // setHovered(true);
    }
  }, [collapsed]);

  const handleMouseLeave = useCallback(() => {
    if (collapsed) {
      // setHovered(false);
    }
  }, [collapsed]);
  // useEffect(() => {
  //   const width = document.body.clientWidth;
  //   console.log(width);
  // }, []);

  return (
    <Layout.Sider
      width={280}
      breakpoint={"lg"}
      onBreakpoint={(e) => {
        if (e === true) {
          _setCollapsed(true);
        } else {
          _setCollapsed(false);
        }
      }}
      theme="light"
      trigger={null}
      collapsed={collapsed}
    >
      <div className="demo-logo-vertical">
        <img src={logo} alt="logo" />
      </div>
      <div className="menu-container">
        <Menu
          theme="light"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          mode="inline"
          onChange={() => {}}
          className="menu-container-con"
          selectedKeys={selectedKeysMemo}
          defaultOpenKeys={openKeys}
          items={menus}
          onClick={({ key }) => {
            goPages(key);
          }}
          overflowedIndicator={null}
        />
      </div>
    </Layout.Sider>
  );
};

export default Sider;
