import { Space, Tooltip } from "antd";
import React from "react";
import { IColumnsTypeP, IOperationConfig } from "../interface";
import IconFont from "@/common/IconFont";

/**
 * 构建操作列config,
 * @param optlist 选项
 * @param optEl 操作栏标题
 */
export const createOptList = (
  optlist?: IOperationConfig,
  optEl?: React.ReactNode
) => {
  if (optlist && optlist.length > 0) {
    const config: IColumnsTypeP = {
      key: "tb_action",
      width: 150,
      fixed: "right",
      onCell(_, idx) {
        return {
          style: {
            paddingTop: 0,
            paddingBottom: 0,
            background: idx % 2 === 0 ? "#f2f2f2" : "#fff",
          },
        };
      },
      title: (optEl || "Action") as string,
      render: (_, record) => {
        return (
          <Space>
            {optlist.map((item, idx) => {
              if (item.hide && item.hide(record)) {
                return null;
              }
              return (
                <React.Fragment key={item.text}>
                  {item.code === "clear_session" ? (
                    <p
                      key={item.text}
                      style={{
                        width: 110,
                        backgroundColor: "#ffeb3b",
                        padding: 2,
                        textAlign: "center",
                        borderRadius: 3,
                        cursor: "pointer",
                      }}
                      onClick={() => item.event(record)}
                    >
                      {item.text}
                    </p>
                  ) : (
                    <Tooltip key={idx} title={item.text}>
                      <p
                        style={{
                          ...iconStyle,
                        }}
                        onClick={() => item.event(record)}
                      >
                        {item.icon && typeof item.icon === "string" ? (
                          <IconFont
                            type={item.icon}
                            style={{
                              fontSize: "1.5rem",
                              color: "#fff",
                            }}
                          />
                        ) : (
                          item.icon
                        )}
                      </p>
                    </Tooltip>
                  )}
                </React.Fragment>
              );
            })}
          </Space>
        );
      },
    };
    return config;
  }
  return null;
};

const iconStyle: React.CSSProperties = {
  color: "#fff",
  cursor: "pointer",
  display: "grid",
  placeItems: "center",
  borderRadius: 6,
  fontSize: 12,
  width: 30,
  height: 28,
};
