import meetingAPI from "@/api/apis/meetings";
import VideoPlayer from "@/components/VideoPlayer";
// import useIsWindows from "@/hooks/useIsWindows";
import { Col, Row } from "antd";
import { FC, useEffect, useState } from "react";
import "../dashboard/index.less";
import useUserInfo from "@/hooks/useUserInfo";
export interface IVenues {
  venues1: Venue;
  venues2: Venue;
  venues3: Venue;
}

export interface Venue {
  hls: string;
  flv: string;
}
const LiveMonitoring: FC = () => {
  const [venues, setVenues] = useState<any>({});
  const { userInfo } = useUserInfo();
  useEffect(() => {
    if (userInfo) {
      meetingAPI.meetingMonitor().then((res) => {
        setVenues(res.venues);
      });
    }
  }, [userInfo]);

  const closeVideo = (url: string) => {
    setVenues((prVenues: any) =>
      Object.fromEntries(
        Object.entries(prVenues).filter(([key]) => key !== url)
      )
    );
  };
  return (
    <section>
      <div className="audit-logs-users audit-logs-box">
        <h2 className="las-pages-title">
          Live Monitoring <span>view</span>
        </h2>
        <div className="currently-assigned-venues-box">
          <h1>Preview</h1>
          <Row gutter={[30, 20]}>
            {Object.keys(venues)?.map((key) => (
              <Col sm={24} md={24} lg={8} key={key}>
                <p className="venues-bus-route-title">{key}</p>
                <div className="venues-bus-route-box">
                  <VideoPlayer
                    source={venues[key]}
                    closeVideo={() => closeVideo(key)}
                    key={key}
                  ></VideoPlayer>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </section>
  );
};

export default LiveMonitoring;
