import { BaseAPI } from "../base/baseApi";

export class OperateLogAPI extends BaseAPI {
  async getAuditLogList(data: IGetAuditLogList) {
    return this.get("/audit/list", data);
  }
}

const operateLogAPI = new OperateLogAPI();
export default operateLogAPI;

export interface IGetAuditLogList {
  page: number;
  pageSize: number;
  event: "created" | "updated" | "deleted";
  target: string;
  old_values: string;
  new_values: string;
  username: string;
  ip_address: string;
  user_agent: string;
  operate_date: string;
  start_date: string;
  end_date: string;
  auditable_type: "users" | "roles" | "subscription_rates" | "subscriptions";
}
