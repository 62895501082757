import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { devtools, persist } from "zustand/middleware";
import { IStoreState, IStoreActions } from "./interface";
import { removeToken } from "./token";
import { IUserType } from "@/common/interface";
import userAPI from "@/api/apis/user";
// import { myRouter } from "@/router";

const LAST_GLOBAL_STORE_KEY = "global-las-store-key";

const initState: IStoreState = {
  userInfo: null,
  twoFaFlag: false,
  menuList: [],
  netWorkLoading: false,
  isRedirect: false,
  userName: "",
  rememberMe: 0,
};
const useStore = create<IStoreState & IStoreActions>()(
  devtools(
    // 本地存储
    persist(
      // 使用immer简化状态更新,以可变的方式更新状态
      immer((set, get) => ({
        ...initState,
        setUserName(userName: string) {
          set(() => ({ userName: userName }));
        },
        setRememberMe(rememberMe: 0 | 1) {
          set((state) => {
            state.rememberMe = rememberMe;
          });
        },
        setNetWorkLoading(netWorkLoading: boolean) {
          set((state) => {
            state.netWorkLoading = netWorkLoading;
          });
        },
        setIsRedirect(isRedirect: boolean) {
          set((state) => {
            state.isRedirect = isRedirect;
          });
        },
        setOrFetchUserInfo(userInfo: IUserType | null) {
          set((state) => {
            state.userInfo = userInfo;
          });
        },
        setTwoFaFlag(twoFaFlag) {
          set((state) => {
            state.twoFaFlag = twoFaFlag;
          });
        },
        async logout(flag = false) {
          const userInfo = get().userInfo;
          if (userInfo?.id && flag === false) {
            await userAPI.userClearSession({ id: userInfo!.id });
            set((state) => {
              state.userInfo = null;
              state.twoFaFlag = false;
            });
            await removeToken();
            // await removeRememberMe();
          } else {
            set((state) => {
              state.userInfo = null;
              state.twoFaFlag = false;
            });
            await removeToken();
          }
        },
        setMenuList(menuList) {
          set((state) => {
            state.menuList = menuList;
          });
        },
      })),
      {
        name: LAST_GLOBAL_STORE_KEY,
        version: 1,
        storage: {
          setItem<T = any>(key: string, value: T) {
            try {
              localStorage.setItem(key, JSON.stringify(value));
            } catch (error) {
              console.error(error);
            }
          },
          getItem: (key: string) => {
            try {
              const loData = localStorage.getItem(key) || "";
              const state = loData ? JSON.parse(loData).state : {};
              return {
                state: {
                  ...state,
                },
              };
            } catch (error) {
              console.error(error);
              return null;
            }
          },
          removeItem(key: string) {
            try {
              localStorage.removeItem(key);
            } catch (error) {
              console.error(error);
            }
          },
        },
      }
    )
  )
);
export default useStore;
