import {FC, useMemo} from "react";
import {Breadcrumb} from "antd";
import {useNavigate} from "react-router-dom";
interface IBreadcrumbPlx{
    items: string[]
}
const BreadcrumbPlx:FC<IBreadcrumbPlx> = ({items}) => {
    const navigate = useNavigate();

    const itemEls = useMemo(() => {
        return items.map((item, index) => {
            if(index === 0){
                return {
                    title: <span className="las-pages-title" onClick={() => navigate(-1)}>
                        <em>{item}</em>
                    </span>
                }
            }
            return {
                title: <span className="las-pages-title">
                        <span>{item}</span>
                    </span>
            }
        })
    }, [navigate, items])

    return <Breadcrumb
        items={itemEls}
    />
}

export default BreadcrumbPlx
