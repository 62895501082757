import { FC, useCallback } from "react";
import "./index.less";
import Template from "@/common/template";
import useEventId from "@/hooks/useEventId";
import useMessageServiceStore from "@/store/messageServiceStore";
import { useNavigate } from "react-router-dom";
import { columns } from "./table";
import moduleAPI from "@/api/apis/module";
import { IOperationConfig, ISearchPage } from "@/common/template/interface";

const Permission: FC = () => {
  const templateId = useEventId();
  const navigate = useNavigate();
  const { confirm, reloadList } = useMessageServiceStore();
  const queryData = (data: ISearchPage) => {
    return moduleAPI.moduleList(data);
  };
  const deletPermission = useCallback(
    async ({ id, name }: { id: number; name: string }) => {
      try {
        confirm(
          `Are you sure you want to delete the permission [${name}]?`,
          async () => {
            await moduleAPI.moduleDelete({ id });
            reloadList(templateId);
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
    [confirm, reloadList, templateId]
  );

  const optList: IOperationConfig = [
    {
      text: "Modify",
      icon: "icon-a-Group3612",
      event: (data) => {
        navigate(`/admin/config/module/${data.id}/edit`, {
          state: data,
        });
      },
    },
    {
      text: "Delete",
      backgroundColor: "warning",
      icon: "icon-a-Group3613",
      event: ({ name, id }) => {
        deletPermission({ id, name });
      },
    },
  ];
  return (
    <section style={{ height: "100%" }}>
      <Template
        id={templateId}
        title={"Permission"}
        queryData={queryData}
        search
        searchKeyWord="keyword"
        rowKey="id"
        optList={optList}
        config={columns}
        create={() => {
          navigate("/admin/config/module/new");
        }}
      />
    </section>
  );
};

export default Permission;
