import roleAPI from "@/api/apis/role";
import Template from "@/common/template";
import { IOperationConfig, ISearchPage } from "@/common/template/interface";
import useEventId from "@/hooks/useEventId";
import useMessageServiceStore from "@/store/messageServiceStore";
import { useNavigate } from "react-router-dom";
import { FC, useCallback } from "react";
import "./index.less";
import { Button } from "antd";

const Role: FC = () => {
  const templateId = useEventId();
  const navigate = useNavigate();
  const { confirm, reloadList } = useMessageServiceStore();
  const queryData = (data: ISearchPage) => {
    return roleAPI.rolePage(data);
  };
  const deleteRole = useCallback(
    async ({ id, name }: { id: number; name: string }) => {
      try {
        confirm(
          `Are you sure you want to delete the role [${name}]?`,
          async () => {
            await roleAPI.roleDelete({ id });
            reloadList(templateId);
          }
        );
      } catch (error) {
        console.error(error);
      }
    },
    [confirm, reloadList, templateId]
  );
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text: string, rowData: any) => {
        return (
          <Button
            type="link"
            onClick={() =>
              navigate(`/admin/roles/${rowData.id}/edit`, {
                state: rowData,
              })
            }
          >
            {text}
          </Button>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  const optList: IOperationConfig = [
    {
      text: "Modify",
      icon: "icon-a-Group3612",
      event: (data) => {
        navigate(`/admin/roles/${data.id}/edit`, {
          state: data,
        });
      },
    },
    {
      text: "Delete",
      backgroundColor: "warning",
      icon: "icon-a-Group3613",
      event: ({ name, id }) => {
        deleteRole({ id, name });
      },
    },
  ];
  return (
    <section style={{ height: "100%" }}>
      <Template
        id={templateId}
        title={
          <h2 className="las-pages-title">
            Role <span>management</span>
          </h2>
        }
        queryData={queryData}
        search
        rowKey="id"
        optList={optList}
        config={columns}
        searchKeyWord="keyword"
        create={() => {
          navigate("/admin/roles/new");
        }}
      />
    </section>
  );
};

export default Role;
