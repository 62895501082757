import { ISearchPage } from "@/common/template/interface";
import { BaseAPI } from "../base/baseApi";

export class VenuesAPI extends BaseAPI {
  //venue可分配列表
  async venuesList() {
    return this.get("/venues/list");
  }
  //venue分页列表
  async venuesPage(params: ISearchPage) {
    return this.get("/venues/page", params);
  }
  //添加venue
  async venuesAdd(data: IvenuesAddParams) {
    return this.post("/venues/add", data);
  }
  //删除venue
  async venuesDelete(params: IvenuesDeleteParams) {
    return this.post("/venues/delete", {}, { params });
  }
  //更新venue
  async venuesUpdate(data: IvenuesUpdateParams) {
    return this.post("/venues/update", data);
  }
  //meeting分配venue
  async venuesAssign(data: IvenuesAssignParams) {
    return this.post("/venues/assign", data, {}, true);
  }
  //venueurl删除
  async venuesUrlDelete(params: IVenuesUrlDeleteParams) {
    return this.post("/venues/url/delete", {}, { params });
  }
  //venueurl修改
  async venuesUrlUpdate(data: IVenuesUrlUpdateParams) {
    return this.post("/venues/url/update", data);
  }
  //venueurl添加
  async venuesUrlAdd(data: IVenuesUrlAddParams) {
    return this.post("/venues/url/add", data);
  }
  //venueurl列表
  async venuesUrlList(params: IVenuesUrlListParams) {
    return this.get("/venues/url/list", params);
  }

  //venueurl批量修改
  async venuesUrlUpdateBatch(data: IVenuesUrlUpdateBatchParams[]) {
    return this.post("/venues/url/update/batch", data);
  }
  //venueurl批量添加
  async venuesUrlAddBatch(data: IVenuesUrlAddBatchParams[]) {
    return this.post("/venues/url/add/batch", data);
  }
  // 获取某个平台的国家信息
  async venuesCountryList(params: IvenuesDeleteParams) {
    return this.get("/venues/country/list", params);
  }

  // 解锁或者锁定
  async venuesCountryBlock(data: IvenuesCountryBlockParams) {
    return this.post("/venues/country/block", data);
  }
}

const venuesAPI = new VenuesAPI();
export default venuesAPI;
interface IvenuesCountryBlockParams {
  id: number; //venue id
  country_name: string; //要操作的国家名称
  block: 1 | 0; //1-锁定 0-解锁
}
interface IvenuesAddParams {
  name: string;
  flv_url: string;
  is_enabled: 1 | 0;
  geoblocks: string;
}
interface IvenuesDeleteParams {
  id: number;
}

interface IvenuesUpdateParams extends IvenuesAddParams {
  id: number;
}
interface IvenuesAssignParams {
  venue_id: number;
  meeting_id: number;
}

interface IVenuesUrlDeleteParams {
  id: string;
}

interface IVenuesUrlAddParams {
  name: string;
  venue_id: string;
  flv_url: string;
  authkey: string;
}
interface IVenuesUrlUpdateParams extends IVenuesUrlAddParams {
  id: string;
}
interface IVenuesUrlListParams {
  venueId: string;
}
interface IVenuesUrlAddBatchParams {
  name: string;
  venue_id: string;
  flv_url: string;
}
interface IVenuesUrlUpdateBatchParams extends IVenuesUrlAddBatchParams {
  id: string;
}
