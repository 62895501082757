import React, { useCallback } from "react";
import { ICustomizeCompProps } from "@/common/interface";
import commonAPI from "@/api/apis/common";

const UploadAvatar: React.FC<ICustomizeCompProps<string>> = ({ onChange }) => {
  const upload = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) return;

      try {
        const formData = new FormData();
        formData.append("file", file);
        const res = await commonAPI.uploadAvatar(formData);
        onChange?.(res);
      } catch (error) {
        console.error(error);
      }
    },
    [onChange]
  );

  return (
    <>
      <input type="file" onChange={upload} />
    </>
  );
};

export default UploadAvatar;
