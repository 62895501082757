import { FC, useCallback, useEffect, useState } from "react";
import "./inex.less";
import { Input, Layout, Space, Button, Image, Form } from "antd";
import useUserInfo from "@/hooks/useUserInfo";
import userAPI from "@/api/apis/user";
import { setToken } from "@/store/token";
import { useNavigate, useNavigation } from "react-router-dom";
import useStore from "@/store/store";
import FormItem from "antd/es/form/FormItem";
import { IUserType } from "@/common/interface";

const Authentication: FC = () => {
  const { userInfo } = useUserInfo();
  const { setOrFetchUserInfo } = useStore();
  const [form] = Form.useForm();
  const navigation = useNavigation();
  const navigate = useNavigate();
  const [qrData, setQrData] = useState<any>({
    code: "",
    secret: "",
  });
  console.log(navigation);
  const get2FAData = useCallback(async () => {
    const qrcodeData = await userAPI.getUserTwoFactorAuthQrcode();
    if (qrcodeData) {
      setQrData(qrcodeData);
    }
  }, []);
  useEffect(() => {
    get2FAData();
  }, [get2FAData]);

  const handelRegister = useCallback(
    async (values: any) => {
      const token = await userAPI.userTwoFactorAuthVerify(values);

      if (token) {
        await setToken(token);
        await setOrFetchUserInfo({
          ...userInfo,
          two_factor_authentication: 1,
        } as IUserType);
        await navigate("/admin");
      }
    },
    [navigate, userInfo, setOrFetchUserInfo]
  );

  return (
    <Layout style={{ height: "100%" }} className="auth-2fa-management-page">
      <h2 className="las-pages-title" style={{ fontWeight: 500 }}>
        Enable Two-factor Authentication
      </h2>
      <div style={{ backgroundColor: "#fff", padding: 20 }}>
        {/* two_factor_authentication */}

        <div style={{ marginBottom: 5 }}>
          <p className="auth-2fa-management-text">
            1.open the two-factor authentication app on your mobile device and{" "}
            <span style={{ fontWeight: "bold" }}>
              scan the following QR-code.
            </span>
          </p>
        </div>

        <Image
          height={200}
          width={200}
          preview={false}
          src={`data:image/jpeg;base64,${qrData.code}`}
        />
        <p
          style={{ marginTop: 15, paddingLeft: 14 }}
          className="auth-2fa-management-text"
        >
          computer using a one-time password authenticator (2FA)
        </p>
        <div style={{ paddingLeft: 14 }}>
          <p className="auth-2fa-management-text">
            if you are unable to scan the QR-code, add manually with the
            following info
          </p>
          <div style={{ paddingLeft: 20, marginTop: 10 }}>
            <p className="auth-2fa-management-text">
              <span className="yuan" />
              Account name： {userInfo?.email}
            </p>
            <p className="auth-2fa-management-text">
              <span className="yuan" />
              Secret Key： {qrData.secret}
            </p>
          </div>
        </div>
        <p
          style={{ marginTop: 10, marginBottom: 5 }}
          className="auth-2fa-management-text"
        >
          2.Enter the 6 digit code from the two-factor authentication app
        </p>
        <div style={{ paddingLeft: 14 }}>
          <Form onFinish={handelRegister} form={form}>
            <Space>
              <FormItem name={"code"} noStyle>
                <Input />
              </FormItem>
              <FormItem noStyle>
                <Button htmlType="submit" type="primary">
                  submit
                </Button>
              </FormItem>
            </Space>
          </Form>
        </div>
      </div>
    </Layout>
  );
};
export default Authentication;
