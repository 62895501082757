import { handleTimeFormat } from "@/common/utils/untilsX";

interface TableData {
  account_number: string;
  request_date: string;
  start_date: string;
  end_date: string;
  billing_cycle: string;
  termination_initiated_date: string;
  termination_by_name: string;
}

interface JsonData<T> {
  heading1: string;
  heading2: string;
  heading3: string;
  heading4: string;
  data: T[];
}

export const exportTableToPDF = <T extends TableData>(
  jsonData: JsonData<T>
) => {
  let table = `
    <div id="container" style="display: flex; align-items: center; flex-direction: column; color: #000000;">
        <h1 style="all: unset; font-size: 24px; font-weight: bold;">
        ${jsonData.heading1}
        </h1>
        <p style="all: unset; font-size: 16px; font-weight: 550; line-height: 24px;">
        ${jsonData.heading2}
        </p>
        <p style="all: unset; font-size: 16px; font-weight: 550; line-height: 24px;">
        ${jsonData.heading3}
        </p>
        <p style="all: unset; font-size: 16px; font-weight: 550; line-height: 24px; color: red;">
        ${jsonData.heading4}
        </p>
        <table style="width: 100%; margin-top: 20px; border-spacing: 0; table-layout: fixed;">
            <thead style="background-color: #2d3f56; color: #ffffff; font-size: 14px; line-height: 28px;">
                <tr>
                    <th style="padding: 8px; text-align: center; font-weight: normal; word-wrap: break-word; white-space: normal; vertical-align: top;">${"Account Number"}</th>
                    <th style="padding: 8px; text-align: center; font-weight: normal; word-wrap: break-word; white-space: normal; vertical-align: top;">${"Request Date"}</th>
                    <th style="padding: 8px; text-align: center; font-weight: normal; word-wrap: break-word; white-space: normal; vertical-align: top;">${"Activation Date"}</th>
                    <th style="padding: 8px; text-align: center; font-weight: normal; word-wrap: break-word; white-space: normal; vertical-align: top;">${"End Date"}</th>
                    <th style="padding: 8px; text-align: center; font-weight: normal; word-wrap: break-word; white-space: normal; vertical-align: top;">${"Billing Cycle"}</th>
                    <th style="padding: 8px; text-align: center; font-weight: normal; word-wrap: break-word; white-space: normal; vertical-align: top;">${"Termination Initiated"}</th>
                    <th style="padding: 8px; text-align: center; font-weight: normal; word-wrap: break-word; white-space: normal; vertical-align: top;">${"Termination By"}</th>
                </tr>
            </thead>
            <tbody>
            ${jsonData.data
              .map(
                (item, idx) => `
                <tr style="font-size: 12px; line-height: 28px; background-color: ${
                  idx % 2 === 0 ? "#f3f4f5" : "#ffffff"
                };page-break-inside: avoid;">
                    <td style="padding: 8px; text-align: left; word-wrap: break-word; white-space: normal; vertical-align: top;">
                    ${item.account_number}
                    </td>
                    <td style="padding: 8px; text-align: left; word-wrap: break-word; white-space: normal; vertical-align: top;">
                    ${handleTimeFormat(
                      item.request_date,
                      "MMM DD, YYYY hh:mm A",
                      false
                    )}
                    </td>
                    <td style="padding: 8px; text-align: left; word-wrap: break-word; white-space: normal; vertical-align: top;">
                    ${handleTimeFormat(
                      item.start_date,
                      "MMM DD, YYYY hh:mm A",
                      false
                    )}
                    </td>
                    <td style="padding: 8px; text-align: left; word-wrap: break-word; white-space: normal; vertical-align: top;">
                    ${handleTimeFormat(
                      item.end_date,
                      "MMM DD, YYYY hh:mm A",
                      false
                    )}
                    </td>
                    <td style="padding: 8px; text-align: left; word-wrap: break-word; white-space: normal; vertical-align: top;">
                    ${item.billing_cycle}
                    </td>
                    <td style="padding: 8px; text-align: left; word-wrap: break-word; white-space: normal; vertical-align: top;">
                    ${handleTimeFormat(
                      item.termination_initiated_date,
                      "MMM DD, YYYY hh:mm A",
                      false
                    )}
                    </td>
                    <td style="padding: 8px; text-align: left; word-wrap: break-word; white-space: normal; vertical-align: top;">
                    ${item.termination_by_name}
                    </td>
                </tr>`
              )
              .join("")}
            </tbody>
        </table>
    </div>`;
  return table;
};
