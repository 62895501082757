import userAPI from "@/api/apis/user";
import useStore from "@/store/store";
import { getToken } from "@/store/token";
import { useCallback, useEffect } from "react";
import { IUserType } from "@/common/interface";
import { useNavigate } from "react-router-dom";
//  [typeof userInfo, typeof loadDetail]
const useUserInfo = () => {
  const { userInfo, setOrFetchUserInfo, logout } = useStore();
  const token = getToken();
  const navigate = useNavigate();

  const loadDetail = useCallback(
    (goPage?: string) => {
      userAPI
        .userDetail()
        .then((res: IUserType) => {
          setOrFetchUserInfo(res);
          // if (goPage) {
          //   setTimeout(() => {
          //     navigate(goPage);
          //   }, 500);
          // }

          // if (res.two_factor_authentication === 1 && twoFlag === true) {

          //   // navigate("/authentication");
          // }
        })
        .catch(() => {
          logout();
          navigate("/login");
          console.error("获取用户信息失败");
        });
    },
    [logout, setOrFetchUserInfo, navigate]
  );

  useEffect(() => {
    if (!userInfo && token) {
      loadDetail();
    }
  }, [loadDetail, userInfo, token]);
  return { userInfo, loadDetail } as {
    userInfo: IUserType | null;
    loadDetail: (goPage?: string) => void;
  };
};

export default useUserInfo;
