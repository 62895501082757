import { IObjectExit, IOnChange, ISomeProperty } from "@/common/interface";
import { compareA_B } from "@/common/utils/untils";
import { useCallback, useMemo, useState } from "react";

/**
 * 只能用于简单对象-因为性能
 * @returns [originData, onFieldsChange, isDifferent, loadData] as IResult<T>
 */
const useDiff = <T>() => {
  const [originData, setOriginData] = useState<T | null>(null);
  /** 数据 */
  const [data, setData] = useState<T | null>(null);
  /** 已经修改的属性 */
  const [diffKeys, setDiffKeys] = useState<IObjectExit<T>>({});
  // diff
  const isDifferent = useMemo(() => {
    return Object.keys(diffKeys).length > 0;
  }, [diffKeys]);

  // onFieldsChange
  const onFieldsChange = useCallback(
    (value: ISomeProperty<T>) => {
      const keys = Object.keys(value) as Array<keyof T>;
      const newDiff = { ...diffKeys };
      keys.forEach((key) => {
        if (originData) {
          if (compareA_B(value[key], originData[key])) {
            delete newDiff[key];
          } else {
            newDiff[key] = true;
          }
        } else {
          // console.log("数据未经过初始化就开始载入");
          newDiff[key] = true;
        }
      });
      // 更新diff
      setDiffKeys(newDiff);
      // 设置value
      if (data) {
        setData({
          ...data,
          ...value,
        });
      } else {
        setData({
          ...value,
        } as T);
      }
    },
    [data, diffKeys, originData]
  );

  // loadData
  const loadData = useCallback((value: T) => {
    setData(value);
    setOriginData(value);
    setDiffKeys({});
  }, []);

  return [originData, onFieldsChange, isDifferent, loadData] as IResult<T>;
};

export default useDiff;

type IResult<T> = [
  T | null,
  IOnChange<ISomeProperty<T>>,
  boolean,
  IOnChange<T>
];
