import { ITrigger } from "@/common/interface";
import { useCallback, useState } from "react";

const useOpen = () => {
  const [oepn, setOpen] = useState(false);

  const switchEvent = useCallback(() => {
    setOpen((vi) => !vi);
  }, []);

  return [oepn, switchEvent] as [boolean, ITrigger];
};

export default useOpen;
