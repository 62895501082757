import logo from "@/assets/spools_logo.png";
import { Col, Layout, Row } from "antd";
import { FC, useCallback, useState } from "react";
import "./index.less";
import { useNavigate } from "react-router-dom";
import userAPI from "@/api/apis/user";
import LoginForm from "./loginForm";
// import useUserInfo from "@/hooks/useUserInfo";
import TwoFactorAuthentication from "./TwoFactorAuthentication";
import useStore from "@/store/store";
import { setToken } from "@/store/token";
import { IUserType } from "@/common/interface";
import md5 from "md5";
const Login: FC = () => {
  const navigate = useNavigate();
  const { setOrFetchUserInfo, setRememberMe, setUserName } = useStore();
  const [show2Fa, setShow2Fa] = useState(false);
  const [TSuserInfo, setTSuserInfo] = useState<IUserType | null>(null);
  const [oldToken, setOldToken] = useState<string>();
  const [loading, setloading] = useState(false);
  const onFinish = useCallback(
    async (values: any) => {
      setloading(true);
      try {
        const paddwordMd5 = md5(values.password);
        const data = {
          ...values,
          method: 1,
          passwd: paddwordMd5,
          password: paddwordMd5,
          remember: values.remember ? 1 : 0,
        };
        userAPI
          .login(data)
          .then(async (resLo) => {
            if (resLo.code === 200) {
              const res: IUserType = await userAPI.userDetail(resLo.data);
              setRememberMe(values.remember);
              if (values.remember) {
                setUserName(res?.email ? res.email : "");
              } else {
                setUserName("");
              }

              if (res.two_factor_authentication === 1) {
                setTSuserInfo(res);
                setShow2Fa(true);
                setOldToken(resLo.data);
              } else {
                await setToken(resLo.data);
                await setOrFetchUserInfo(res);
                await navigate("/admin");
              }
            }
            setloading(false);
          })
          .catch((err) => console.log(err))
          .finally(() => setloading(false));
      } catch (error) {
        console.log(error);
      }
    },
    [navigate, setOrFetchUserInfo, setRememberMe, setUserName]
  );
  const onFinish2Fa = useCallback(
    async (values: any) => {
      if (oldToken) {
        const token = await userAPI.userTwoFactorAuthVerify(values, oldToken);
        if (token) {
          await setToken(token);
          await setOrFetchUserInfo(TSuserInfo);
          await navigate("/admin");
        }
      }
    },
    [TSuserInfo, navigate, setOrFetchUserInfo, oldToken]
  );
  return (
    <Layout className="login-page">
      <Row wrap={false}>
        <Col xs={0} lg={16} md={16} sm={0}>
          <div className="login-page-left">
            <div className="login-logo-container">
              <img src={logo} alt="logo" />
            </div>
          </div>
        </Col>
        <Col md={8} xs={24} sm={24}>
          {show2Fa ? (
            <TwoFactorAuthentication onVerify={onFinish2Fa} />
          ) : (
            <LoginForm loading={loading} onFinish={onFinish} />
          )}
        </Col>
      </Row>
    </Layout>
  );
};

export default Login;
