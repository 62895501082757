import useDiff from "@/hooks/useDiff";
import useFormP from "@/hooks/useFormP";
import { Button, Form, Input, Space } from "antd";
import React, { FC, useCallback } from "react";
import { ICallback } from "../../interface";
import { IFormProps } from "../../interface";
import "./index.less";
import useResourceData from "@/hooks/useResourceData";
import EInfoType from "@/store/infoType";
const FormS: FC<
  IFormProps & {
    onFieldsChange?: ICallback<any>;
    forceShowSubmit?: boolean;
    children?: React.ReactNode;
    resetCallback?: () => void;
    footerBtnConfig?: {
      closeBtn?: boolean;
      okText?: string;
    };
  }
> = (props) => {
  const [originData, onFieldsChange, isDifferent, loadDiffData] =
    useDiff<any>();
  const [formData, submit] = useFormP(props.submitEvent);
  const onFieldsChangeProps = props.onFieldsChange;
  // 载入数据
  const loadFormData = useCallback(
    (data: any) => {
      loadDiffData(data);
      formData.setFieldsValue(data);
      onFieldsChangeProps && onFieldsChangeProps(data);
    },
    [loadDiffData, formData, onFieldsChangeProps]
  );
  const resetData = useCallback(() => {
    formData.resetFields();
    loadFormData(originData);
    props.resetCallback && props.resetCallback();
  }, [loadFormData, originData, formData, props]);
  //载入单条数据
  useResourceData(EInfoType.form_update, props.id, loadFormData);
  return (
    <Form
      className="comp-form-simple"
      form={formData}
      onFinish={submit}
      onFieldsChange={(e: any[]) => {
        if (e.length > 0) {
          const obj = e[0];
          if (obj.name.length > 0) {
            const changeField = {
              [obj.name.toString()]: obj.value,
            };
            onFieldsChange(changeField);
            onFieldsChangeProps && onFieldsChangeProps(changeField);
          }
        }
      }}
      layout={props.layout || "vertical"}
      autoComplete="off"
      colon={false}
    >
      {props.title && <p className="title">{props.title}</p>}
      {props.children}
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="mode" hidden>
        <Input />
      </Form.Item>
      <div>
        {(isDifferent || props.forceShowSubmit) && (
          <Space style={{ marginTop: 15 }}>
            {!props.footerBtnConfig?.closeBtn && (
              <Button
                style={{ minWidth: 100 }}
                onClick={resetData}
                type="default"
              >
                Cancel
              </Button>
            )}
            <Button style={{ minWidth: 100 }} type="primary" htmlType="submit">
              {props.footerBtnConfig?.okText || "ok"}
            </Button>
          </Space>
        )}
      </div>
    </Form>
  );
};

export default FormS;
