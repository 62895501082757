import { Tooltip } from "antd";
import React, { FC, useRef, useState } from "react";

interface IProps {
  children: React.ReactNode;
  title: React.ReactNode;
}

const EllipsisTooltip: FC<IProps> = ({ children, title }) => {
  const [visible, setVisible] = useState(false);
  const container = useRef<HTMLDivElement>(null);

  const handleVisibleChange = (updatedVisible: any) => {
    if (container.current) {
      if (container.current.clientWidth < container.current.scrollWidth) {
        setVisible(updatedVisible);
      }
    }
  };

  return (
    <Tooltip
      placement="left"
      open={visible}
      onOpenChange={handleVisibleChange}
      title={title}
      style={{ width: "100%" }}
    >
      <p
        ref={container}
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          // display: "inline-block",
          whiteSpace: "nowrap",
        }}
      >
        {children}
      </p>
    </Tooltip>
  );
};

export default EllipsisTooltip;
