import { ConfigProvider } from "antd";
import myTheme from "@/config/theme/theme.json";
import "./App.css";
import AppRouter from "./router";
import GlobalCompDom from "@/common/confirm";

function App() {
  return (
    <ConfigProvider theme={myTheme}>
      <AppRouter />
      <GlobalCompDom />
    </ConfigProvider>
  );
}

export default App;
