
import useStore from "@/store/store";
import { useEffect, useState } from "react";

const useSubModules = () => {
  const [menu, setMenu] = useState<any>([]);
  const { userInfo } = useStore();

  useEffect(() => {
    let menuList: string[] = [];
    if (userInfo?.access_module_codes) {
      menuList = userInfo.access_module_codes
        .map((item: any) => {
          if (Array.isArray(item.child) && item.child.length) {
            return item.child.map((it: any) => {
              return it.code;
            });
          }
          return null;
        })
        .filter((item: any) => item)
        .flat(1);
    }

    setMenu(menuList);
  }, [userInfo]);

  return menu;
};

export default useSubModules;
