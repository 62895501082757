import CustomRadio from "@/components/customRadio";
import {
  Layout,
  Row,
  Col,
  Select,
  notification,
  Spin,
  Empty,
  Space,
  Tooltip,
} from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import "./index.less";
import meetingAPI from "@/api/apis/meetings";
import useUILoading from "@/hooks/useLoading";
import { DefaultOptionType } from "antd/es/select";
import { processVenuesListData } from "@/common/utils/untils";
import { VenuesDataItem } from "@/common/interface";
import { MinusCircleFilled } from "@ant-design/icons";
import VideoPlayer from "@/components/VideoPlayer";
// import useIsWindows from "@/hooks/useIsWindows";
import venuesAPI from "@/api/apis/venues";
import useUserInfo from "@/hooks/useUserInfo";
// const venuesList: DefaultOptionType[] = [
//   { value: 1, label: "venuse1" },
//   { value: 2, label: "venuse2" },
//   { value: 3, label: "venues3" },
// ];

const Venues: FC = () => {
  const [loading, open, close] = useUILoading();
  const [meetingList, setMeetingList] = useState<VenuesDataItem[]>([]);
  const [notificationApi, contextHolder] = notification.useNotification();
  const [venuesList, setVenuesList] = useState<DefaultOptionType[]>([]);
  const [monitor, setMonitor] = useState<any>({});
  // const isWindows = useIsWindows();
  const { userInfo } = useUserInfo();
  const getVedio = useCallback(() => {
    if (userInfo) {
      meetingAPI.meetingMonitor().then((res) => {
        setMonitor(res.venues);
      });
    }
  }, [userInfo]);
  useEffect(() => {
    venuesAPI.venuesList().then((res) => {
      if (Array.isArray(res)) {
        setVenuesList(
          res.map((item) => ({ value: item.id, label: item.name }))
        );
      }
    });
  }, []);

  const getMeetingList = useCallback(async () => {
    open();
    const res: any[] = await meetingAPI.mettingList();
    close();
    if (res) {
      const _res: any = res.map((item: any) => {
        const used: any = [];
        res.forEach((i) => {
          if (i.id !== item.id) {
            i.venue_id !== 0 && used.push(i.venue_id);
          }
        });
        const _item = {
          ...item,
          venuesList,
          used,
        };
        return _item;
      });
      setMeetingList(processVenuesListData(_res));
    }
  }, [open, close, venuesList]);
  useEffect(() => {
    getMeetingList();
  }, [getMeetingList]);
  // const handeSwitchMeeting = useCallback(
  //   (status: 1 | 2, rowData: any) => {
  //     const _data = { status, id: rowData?.id };
  //     console.log(_data);
  //     const msg =
  //       status === 2 ? "Enabled successfully" : "Disabled successfully";
  //     open();
  //     meetingAPI
  //       .meetingSwitchStatus(_data)
  //       .then((res) => {
  //         if (res !== null) {
  //           getMeetingList();
  //           notificationApi.success({ message: msg });
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       })
  //       .finally(close);
  //   },
  //   [open, close, getMeetingList, notificationApi]
  // );
  const submitSetVenues = useCallback(
    (e: number, meeting_id: number, is_live: 1 | 0) => {
      if (is_live === 0) {
        notificationApi.warning({
          message: "The meeting is not enabled.",
        });
        return;
      }
      const _data = { meeting_id, venue_id: e ? e : 0 };
      open();
      venuesAPI
        .venuesAssign(_data)
        .then((res) => {
          if (res.code === 200) {
            getMeetingList();
            getVedio();
            notificationApi.success({
              message: "Settings saved successfully.",
            });
          }
          close();
        })
        .catch((err) => {
          console.error(err);
          close();
        })
        .finally(close);
    },
    [close, open, notificationApi, getMeetingList, getVedio]
  );
  const handleRelease = useCallback(
    (meeting_id: number, is_live: 1 | 0, isAssigned: number) => {
      if (isAssigned === 0) {
        notificationApi.warning({
          message: "The meeting has not been assigned a venue.",
        });
        return;
      }
      submitSetVenues(0, meeting_id, is_live);
    },
    [notificationApi, submitSetVenues]
  );

  const closeVideo = (url: string) => {
    setMonitor((prVenues: any) =>
      Object.fromEntries(
        Object.entries(prVenues).filter(([key]) => key !== url)
      )
    );
  };
  useEffect(() => {
    getVedio();
  }, [getVedio]);
  return (
    <Layout style={{ height: "100%" }}>
      {contextHolder}
      <Spin
        style={{ position: "fixed", left: "50%", top: "40%", zIndex: 9 }}
        spinning={loading}
      ></Spin>
      <h2 className="las-pages-title">
        Venue Assignment <span>management</span>
      </h2>
      <Row className="venues-pages-subbox" wrap={false} gutter={16}>
        <Col span={12}>
          <section className="page-container venues-pages-container">
            <h3>Assign a venue from the dropdowm</h3>
            {meetingList?.length ? (
              meetingList.map((item: any, idx) => {
                return (
                  <div key={item.id} className="venues-row-box">
                    <Row>
                      <Col span={10}>
                        <CustomRadio
                          disabled
                          text={item.name}
                          true={1}
                          false={0}
                          value={item.is_live}
                        />
                      </Col>
                      <Col span={10}>
                        <Space>
                          <Select
                            value={item.venue_id !== 0 ? item.venue_id : null}
                            style={{ width: 200 }}
                            onChange={(e) =>
                              submitSetVenues(e, item.id, item.is_live)
                            }
                            options={item.venuesList}
                          />
                          {item.venues !== 0 && (
                            <Tooltip title={"Release"}>
                              <span
                                style={{
                                  fontSize: 20,
                                  color: "#999",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleRelease(item.id, 1, item.venue_id);
                                }}
                              >
                                <MinusCircleFilled />
                              </span>
                            </Tooltip>
                          )}
                        </Space>
                      </Col>
                    </Row>
                  </div>
                );
              })
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
          </section>
        </Col>
        <Col span={12}>
          <section className="venues-pages-container-right">
            <h3>Preview</h3>
            <Space direction="vertical" size={20} style={{ width: "100%" }}>
              {Object.keys(monitor)?.map((key) => (
                <div key={key}>
                  <p className="venues-bus-route-title overflow-ellipsis">
                    {key}
                  </p>
                  <div className="venues-bus-route-box">
                    <VideoPlayer
                      key={key}
                      closeVideo={() => closeVideo(key)}
                      // source={isWindows ? monitor[key].hls : monitor[key].hls}
                      source={monitor[key]}
                    ></VideoPlayer>
                  </div>
                </div>
              ))}
            </Space>
          </section>
        </Col>
      </Row>
    </Layout>
    // </Spin>
  );
};

export default Venues;
