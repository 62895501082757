// import useStore from "./store";

export const TOKEN_KEN = "las-admin-token";
export const REMENBERME_KEY = "las-admin-remember-me";

export function removeToken() {
  localStorage.removeItem(TOKEN_KEN);
}

export function getToken() {
  return localStorage.getItem(TOKEN_KEN) as string;
}

export function setToken(token: string) {
  localStorage.removeItem(TOKEN_KEN);
  localStorage.setItem(TOKEN_KEN, token);
}
