import { FC } from "react";
import { Button, Space } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { ITrigger } from "@/common/interface";
interface IProps {
  cancelText?: string;
  okText?: string;
  submit?: ITrigger;
  cancel?: ITrigger;
  children?: React.ReactNode;
  size?: SizeType;
  footerBtnConfig?: {
    closeBtn?: boolean;
    okText?: string;
  };
}

const FooterP: FC<IProps> = ({
  size,
  submit,
  cancel,
  okText,
  cancelText,
  footerBtnConfig = {
    closeBtn: true,
    justify: "end",
    okText,
  },
  children,
}) => {
  const click = submit;
  if (!submit) {
    return null;
  }
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "end",
      }}
    >
      <Space>
        {children}
        {footerBtnConfig.closeBtn && (
          <Button size={size} type="default" onClick={cancel}>
            {cancelText || "Cancel"}
          </Button>
        )}
        <Button size={size} type="primary" onClick={click}>
          {okText || footerBtnConfig.okText || "Save"}
        </Button>
      </Space>
    </div>
  );
};

export default FooterP;
