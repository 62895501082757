import { IColumnsTypeP } from "@/common/interface";

export interface ConfigIDataItem {
  Category: string;
  Key: string;
  Sort: number;
  Value: string;
}

export const columns: IColumnsTypeP<ConfigIDataItem>[] = [
  {
    title: "Category",
    dataIndex: "Category",
    key: "Category",
  },
  {
    title: "Key",
    dataIndex: "Key",
    key: "Key",

  },
  {
    title: "Value",
    dataIndex: "Value",
    key: "Value",
  },
];
