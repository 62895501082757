import { BaseAPI } from "../base/baseApi";

export class CommonAPI extends BaseAPI {
  uploadAvatar(data: FormData) {
    return this.post("/file/upload", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

const commonAPI = new CommonAPI();
export default commonAPI;
