import userAPI from "@/api/apis/user";
import Template from "@/common/template";
import { IOperationConfig } from "@/common/template/interface";
import useEventId from "@/hooks/useEventId";
import { FC, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import useMessageServiceStore from "@/store/messageServiceStore";
import "./index.less";
import { Button, notification } from "antd";
import { IColumnsTypeP } from "@/common/interface";
import useSubModules from "@/hooks/useSubModules";
// import { Tag } from "antd";

export interface UserIDataItem {
  name: string;
  email: string;
  user_role: string;
  id: number;
}

const User: FC = () => {
  const templateId = useEventId();
  const navigate = useNavigate();
  const subModulesList = useSubModules();

  const [notificationApi, contextHolder] = notification.useNotification();
  const { confirm, reloadList } = useMessageServiceStore();
  const queryData = (data: any) => {
    return userAPI.getUserPage(data);
  };
  const toModify = useCallback(
    (data: any) => {
      navigate(`/admin/users/${data.id}/edit`, {
        state: data,
      });
    },
    [navigate]
  );
  const changManualExecution = useMemo(() => {
    if (Array.isArray(subModulesList) && subModulesList?.length > 0) {
      return !subModulesList.includes("clear_session") ? true : false;
    } else {
      return true;
    }
  }, [subModulesList]);
  const optList: IOperationConfig = [
    {
      text: "Modify",
      icon: "icon-a-Group3612",
      event: toModify,
    },
    {
      text: "Delete",
      backgroundColor: "warning",
      icon: "icon-a-Group3613",
      event: ({ name, id }) => {
        confirm(`Are you sure you want to delete the user [${name}]?`, () => {
          userAPI
            .userDelete({ id })
            .then(() => {
              notificationApi.success({ message: "Delete successfully" });
              reloadList(templateId);
            })
            .catch((err) => {
              console.error(err);
            });
        });
      },
    },
    {
      text: "Clear Session",
      code: "clear_session",
      hide: (e: any) => {
        if (changManualExecution) {
          return true;
        }
        return e.is_logged_in === 0 && changManualExecution === false;
      },
      event: (data) => {
        console.log(data);

        confirm(
          `Are you sure to Clear Session of the user [${data.name}]?`,
          () => {
            userAPI
              .userClearSession({ id: data.id })
              .then(() => {
                notificationApi.success({ message: "Clear successfully" });
                reloadList(templateId);
              })
              .catch((err) => {
                console.error(err);
              });
          }
        );
      },
    }, 
  ];
  const columns: IColumnsTypeP<UserIDataItem>[] = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      render(text, data) {
        return (
          <Button onClick={() => toModify(data)} type="link">
            {text}
          </Button>
        );
      },
    },
    {
      key: "email",
      title: "Email",
      dataIndex: "email",
    },
    {
      key: "user_role",
      title: "Role",
      dataIndex: "user_role",
    },
  ];
  return (
    <section style={{ height: "100%" }}>
      {contextHolder}
      <Template
        id={templateId}
        title={
          <h2 className="las-pages-title">
            Users <span>management</span>
          </h2>
        }
        optList={optList}
        queryData={queryData}
        search
        searchKeyWord="keyword"
        rowKey={"id"}
        config={columns}
        create={() => {
          navigate("/admin/users/new");
        }}
      />
    </section>
  );
};

export default User;
