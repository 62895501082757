import useUILoading from "@/hooks/useLoading";
import useResourceData from "@/hooks/useResourceData";
import EInfoType from "@/store/infoType";
import {
  Button,
  Col,
  message,
  Pagination,
  PaginationProps,
  Row,
  Space,
  Table,
} from "antd";
// import { TablePaginationConfig } from "antd/lib/table";
// import { SorterResult } from "antd/lib/table/interface";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IMap, ITrigger } from "../interface";
import { ellopsisOnCell, ellopsisRender } from "../utils/untilsX";
import CustomSearch from "./components/customSearch";
import { createOptList } from "./components/optList";
import "./index.less";
import { IOperationConfig, ISearchPage } from "./interface";
import { filterEmptyProperties } from "../utils/untils";
import SelectP from "../form/SelectP";
// import useResizeObserver from "@/hooks/useResizeObserver";
// eslint-disable-next-line
import html2pdf from "html2pdf.js";

interface TemplateProps {
  queryData: (data: ISearchPage) => Promise<any> | {};
  id: number;
  search?: boolean;
  config: any;
  create?: ITrigger;
  rowKey?: string;
  optList?: IOperationConfig;
  title?: React.ReactNode;
  subTitle?: string;
  moreParams?: IMap;
  scroll?: { x?: number | string; y?: number | string };
  searchKeyWord?: string;
  exportConfig?: {
    text?: string;
    queryApi: (data: any) => Promise<any>;
    fileName: string;
    pdfQueryApi: (data: any) => Promise<string>;
  };
}

const Template: FC<TemplateProps> = (props) => {
  const { search, moreParams, searchKeyWord } = props;
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, open, close] = useUILoading();
  const [tableData, setTableData] = useState<any[]>([]);
  const _pagesize = calculateRowsToRequest();
  // const [theadHeight] = useState<number>(43);
  const tableRef = useRef<any>(null);
  const { current: _exportConfig } = useRef(props.exportConfig);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: _pagesize,
  });
  const [total, setTotal] = useState(0);
  const [tabParams, setTabParams] = useState({});
  const { current: handelCreate } = useRef(props.create);
  const _moreParams = useMemo(() => {
    if (filterEmptyProperties(moreParams)) {
      setPagination((prevPagination) => ({
        ...prevPagination,
        page: 1,
      }));
      return filterEmptyProperties(moreParams);
    }
    return {};
  }, [moreParams]);
  const params = useMemo(
    () => ({
      ...tabParams,
      ...pagination,
      ..._moreParams,
    }),
    [tabParams, pagination, _moreParams]
  );
  const { current: _queryData } = useRef(props.queryData);
  const tableRowConfig: any = useMemo(() => {
    let _config = props.config;

    const conf = _config.map((cof: { tipMessage: any; title: any }) => {
      const _cof: any = { ...cof };
      if (typeof _cof.title === "string" && !cof.tipMessage) {
        _cof.title = cof.title;
      }
      if (!_cof.render) {
        _cof.render = ellopsisRender;
      }
      if (!_cof.onCell) {
        _cof.onCell = ellopsisOnCell;
      }
      return _cof;
    });
    if (!props.optList) {
      return conf;
    }
    const optList: any[] = [];
    props.optList.forEach((item: any) => {
      optList.push(item);
    });
    if (optList.length < 1) {
      return conf;
    }
    const optConf = createOptList(optList);
    optConf && conf.push(optConf as any);
    return conf;
  }, [props]);
  const queryEvent = useCallback(async () => {
    open();
    try {
      const _data: any = { ...params };
      const result = await _queryData(_data);
      if (result) {
        let resultData: any[] = result.data;
        if (Array.isArray(resultData) && resultData.length > 0) {
          setTableData(resultData);
        } else {
          setTableData([]);
        }
        setTotal(result.total);
      } else {
        throw Error("接口数据请求失败");
      }
    } catch (error) {
      console.log(error);
    } finally {
      close();
    }
  }, [_queryData, close, open, params]);

  const handlePageChange = useCallback((page: number, pageSize?: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: page,
      pageSize: pageSize || prevPagination.pageSize,
    }));
  }, []);
  const handlePageSizeChange = useCallback((pageSize: number) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: 1,
      pageSize: pageSize,
    }));
  }, []);
  useEffect(() => {
    queryEvent();
  }, [queryEvent]);

  useResourceData(EInfoType.list_reload, props.id, queryEvent);
  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return <p className="pagina-btn">Previous</p>;
    }
    if (type === "next") {
      return <p className="pagina-btn">Next</p>;
    }
    return originalElement;
  };

  // const handelExportExcel = useCallback(
  //   async (type: "excel" | "pdf") => {
  //     try {
  //       // 根据文件类型选择 API
  //       const res =
  //         type === "excel"
  //           ? await _exportConfig!.queryApi(params)
  //           : await _exportConfig!.pdfQueryApi(params);

  //       if (!res) {
  //         throw new Error("API response is null");
  //       }

  //       // 根据文件类型设置 MIME 类型和文件扩展名
  //       const blob = new Blob([res], {
  //         type:
  //           type === "excel"
  //             ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  //             : "application/pdf",
  //       });

  //       // 创建下载链接
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement("a");
  //       a.href = url;
  //       a.download = `${_exportConfig!.fileName || "logs"}.${
  //         type === "excel" ? "xlsx" : "pdf"
  //       }`;
  //       document.body.appendChild(a);
  //       a.click();
  //       window.URL.revokeObjectURL(url);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   },
  //   [_exportConfig, params]
  // );
  const handleDownloadOpen = useCallback(() => {
    messageApi.open({
      type: "loading",
      content: "Downloading..",
      duration: 0,
    });
  }, [messageApi]);
  const handleDownloadClose = useCallback(() => {
    messageApi.destroy();
  }, [messageApi]);
  const handelExportExcel = useCallback(async () => {
    try {
      await handleDownloadOpen();
      // 根据文件类型选择 API
      const res = await _exportConfig!.queryApi(params);

      if (!res) {
        throw new Error("API response is null");
      }

      // 根据文件类型设置 MIME 类型和文件扩展名
      const blob = new Blob([res], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      // 创建下载链接
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${_exportConfig!.fileName || "logs"}.${"xlsx"}`;
      document.body.appendChild(a);
      a.click();
      await handleDownloadClose();

      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error(error);
    }
  }, [_exportConfig, params, handleDownloadOpen, handleDownloadClose]);

  // 导出 PDF
  const exportToPdf = useCallback(async () => {
    await handleDownloadOpen();
    const tableHtml = await _exportConfig?.pdfQueryApi(params);
    const opt = {
      margin: 0.3,
      filename: `${_exportConfig!.fileName || "logs"}.${"pdf"}`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      // jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
    };
    await handleDownloadClose();
    html2pdf().from(tableHtml).set(opt).save();
  }, [params, _exportConfig, handleDownloadOpen, handleDownloadClose]);

  return (
    <section>
      {contextHolder}
      {_exportConfig && (
        <div style={{ paddingLeft: 15, paddingTop: 15 }}>
          <Space size={0}>
            {/* <Button style={{ backgroundColor: "#74798C", color: "#fff" }}>
              {_exportConfig.text || "Excel"}
            </Button>
            {_exportConfig?.pdfQueryApi && (
              <Button
               
              >
                {_exportConfig.text || "PDF"}
              </Button>
            )} */}
            <div
              onClick={() => handelExportExcel()}
              className="export-leftbtn"
              // style={{
              //   borderRadius: _exportConfig.pdfQueryApi
              //     ? "6px 0px 0px 6px"
              //     : "6px",
              // }}
            >
              {_exportConfig.text || "Excel"}
            </div>
            {/* {_exportConfig?.pdfQueryApi && ( */}
            <div onClick={exportToPdf} className="export-rightbtn">
              {_exportConfig.text || "PDF"}
            </div>
            {/* )} */}
          </Space>
        </div>
      )}
      <div className="comp-template">
        {props.title && (
          <div className="las-pages-title">
            {props.title} <span>{props.subTitle}</span>
          </div>
        )}
        {handelCreate && (
          <div className="search-bar">
            <Space size={15}>
              <Button
                style={{ backgroundColor: "#00bcd4", borderColor: "#00bcd4" }}
                type="primary"
                onClick={handelCreate}
              >
                Add
              </Button>
            </Space>
          </div>
        )}
        <div className="temppate-container">
          <div style={{ marginBottom: 8 }}>
            <Row justify={"space-between"}>
              <Col>
                <Space>
                  <span>Show</span>
                  <SelectP
                    style={{ width: 70 }}
                    defaultValue={_pagesize}
                    onChange={handlePageSizeChange}
                    data={Array.from(
                      new Set([_pagesize, 10, 25, 50, 100])
                    ).sort((a, b) => a - b)}
                  ></SelectP>
                  <span>entries</span>
                </Space>
              </Col>
              <Col>
                {search && (
                  <Space>
                    <span>Search:</span>
                    <CustomSearch
                      submit={(data: { name: any }) => {
                        setPagination((prevPagination) => ({
                          ...prevPagination,
                          page: 1,
                        }));
                        setTabParams((prevParams) => {
                          return {
                            ...prevParams,
                            [searchKeyWord ? searchKeyWord : "name"]: data.name,
                          };
                        });
                      }}
                      buttonName={""}
                      keyName={"name"}
                    />
                  </Space>
                )}
              </Col>
            </Row>
          </div>

          <Table
            pagination={false}
            bordered={true}
            showSorterTooltip={false}
            columns={tableRowConfig}
            ref={tableRef}
            dataSource={tableData ? tableData : []}
            loading={loading}
            rowKey={props.rowKey || "id"}
            rowClassName={(_: any, index: number) =>
              index % 2 === 0 ? "even-row" : ""
            }
            showHeader
            scroll={props?.scroll ? props.scroll : {}}
            style={{ borderBottom: "1px solid #f5f5f5" }}
          />
          {/* {total >= calculateRowsToRequest() && ( */}
          <div style={{ marginTop: 15 }}>
            <Row justify={"space-between"}>
              <Col>
                Showing {(pagination.page - 1) * pagination.pageSize + 1} to{" "}
                {Math.min(pagination.page * pagination.pageSize, total)} of{" "}
                {total} entries
              </Col>
              <Col>
                <Pagination
                  current={pagination.page}
                  pageSize={pagination.pageSize}
                  total={total}
                  itemRender={itemRender}
                  onChange={handlePageChange}
                  showSizeChanger={false}
                  onShowSizeChange={handlePageChange}
                />
              </Col>
            </Row>
          </div>
          {/* )} */}
        </div>
      </div>
    </section>
  );
};

export default Template;

function calculateRowsToRequest() {
  const windowHeight = window.innerHeight;
  const headerHeight = 84;
  const paddingHeight = 90;
  const searchHeight = 70;
  const availableHeight =
    windowHeight - headerHeight - paddingHeight * 2 - searchHeight;
  const rowHeight = 53;
  const rowsToRequest = Math.floor(availableHeight / rowHeight);
  return rowsToRequest <= 3 ? 3 : rowsToRequest;
}
