import SelectP from "@/common/form/SelectP";
import { Form, Input, Layout, notification, Space } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import "./index.less";
// import { useParams } from "oh-router-react";
import roleAPI from "@/api/apis/role";
import userAPI from "@/api/apis/user";
import FormS from "@/common/form/FormS";
import { ISelectItem } from "@/common/interface";
import { filterEmptyProperties } from "@/common/utils/untils";
import UploadAvatar from "@/components/uploadAvatar/inputFile";
import useEventId from "@/hooks/useEventId";
import useMessageServiceStore from "@/store/messageServiceStore";
import { useLocation, useNavigate } from "react-router-dom";
import CheckButtonP from "@/components/checkButton";
import BreadcrumbPlx from "@/common/breadcrumbPlx";
import md5 from "md5";
const NewUser: FC = () => {
  const formId = useEventId();
  const { formUpdate } = useMessageServiceStore();
  const navigate = useNavigate();
  const [roleList, setRoleList] = useState<ISelectItem[]>([]);
  const [hidePwd, setHidePwd] = useState<boolean>(false);
  const [status2fa, setStatus2fa] = useState<0 | 1>(0);
  const location = useLocation();
  useEffect(() => {
    const data: any = location.state;
    if (data) {
      const roleId = roleList.find((item) => item.name === data.user_role);
      const _data = {
        id: data.id,
        avatar: data?.avatar,
        name: data?.name,
        email: data?.email,
        bio: data?.bio,
        role_id: roleId?.id,
        mode: "edit",
        status_2fa: data?.status_2fa,
      };
      setStatus2fa(data?.status_2fa);
      formUpdate(formId, _data);
      setHidePwd(true);
    }
  }, [location, formId, formUpdate, roleList]);

  const getRoleSelect = useCallback(async () => {
    const res = await roleAPI.getRoleSelect();
    const _list: ISelectItem[] = [];
    if (res) {
      for (const key in res) {
        if (Reflect.hasOwnProperty.call(res, key)) {
          _list.push({
            id: res[key],
            name: key,
          });
        }
      }
    }
    await setRoleList(_list);
    if (!location.state && _list.length > 0) {
      await formUpdate(formId, { role_id: _list[0].id });
    }
  }, [formUpdate, formId, location]);

  useEffect(() => {
    getRoleSelect();
  }, [getRoleSelect]);

  const submit = useCallback(
    async (value: any) => {
      try {
        const data = filterEmptyProperties(value);
        data?.password_conform && delete data.password_conform;
        // md5(values.password)
        if (value?.status_2fa === 0 && status2fa === 1) {
          await userAPI.userTwoFactorAuthDisable({ id: data?.id });
        }
        if (data.password) {
          data.password = md5(data.password);
          data.ver = 2;
        }
        delete data.status_2fa;
        if (data?.mode === "edit") {
          delete data.mode;
          await userAPI.updatePage(data);
          notification.success({
            message: "Update success",
          });
        } else {
          // 新增
          await userAPI.addUser(data);
          notification.success({
            message: "Add success",
          });
        }
        navigate("/admin/users");
      } catch (error) {
        console.error(error);
      }
    },
    [navigate, status2fa]
  );
  return (
    <Layout>
      <BreadcrumbPlx items={["User", location.state ? "update" : "new"]} />
      <FormS
        id={formId}
        footerBtnConfig={{ okText: hidePwd ? "Edit" : "Add" }}
        submitEvent={submit}
        layout="vertical"
      >
        <main className="page-container user-new-main">
          <div style={{ width: 500 }}>
            <Form.Item
              rules={[
                {
                  required: true,
                  type: "string",
                  message: "Username is required",
                },
              ]}
              name="name"
              label="Username"
            >
              <Input />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  type: "email",
                  message: "Please enter a valid email address",
                },
              ]}
              name="email"
              label="Email"
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  message: "Please enter your password",
                },
                () => ({
                  validator(_, value) {
                    const reg = /^[a-zA-Z\d!@#$%^.&*]{8,32}$/;
                    if (value && !reg.test(value)) {
                      return Promise.reject(
                        new Error(
                          "Password must be 8-16 characters and contain both numbers and letters/special characters"
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input.Password autoComplete="off" />
            </Form.Item>
            <Form.Item
              dependencies={["password"]}
              name="password_conform"
              label="Password again"
              rules={[
                {
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      getFieldValue("password") &&
                      (!value || getFieldValue("password") !== value)
                    ) {
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input.Password autoComplete="off" />
            </Form.Item>
            <Form.Item name="role_id" label="Role">
              <SelectP data={roleList} />
            </Form.Item>
            <Form.Item name="avatar" label="Avatar">
              <UploadAvatar />
            </Form.Item>
            <Form.Item name="bio" label="Bio">
              <Input.TextArea
                maxLength={500}
                showCount
                autoSize={{ minRows: 5 }}
              />
            </Form.Item>
            {status2fa === 1 && (
              <Form.Item>
                <Space size={50}>
                  <span>Disable 2FA</span>
                  <Form.Item name="status_2fa" noStyle initialValue={1}>
                    <CheckButtonP true={1} false={0} />
                  </Form.Item>
                </Space>
              </Form.Item>
            )}
          </div>
        </main>
      </FormS>
    </Layout>
  );
};

export default NewUser;
