import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { devtools } from "zustand/middleware";
import EInfoType from "@/store/infoType";
import { ITrigger } from "@/common/interface";

interface IMessageInfoType {
  type: EInfoType | number;
  value?: any;
}

export interface IStoreState {
  messageInfo: IMessageInfoType | null;
}

export interface IStoreActions {
  reloadList: (id: number) => void;
  confirm: (
    text: string,
    event: ITrigger,
    config?: {
      okText?: string;
      width?: number;
      closable?: boolean;
    }
  ) => void;
  formUpdate: (id: number, data: any) => void;
  updateOnce: (id: number, data: any) => void;
  createEvent: (id: number) => void;
}

const useMessageServiceStore = create<IStoreState & IStoreActions>()(
  devtools(
    immer((set) => ({
      messageInfo: null,
      reloadList(id: number) {
        set((state) => {
          state.messageInfo = {
            type: EInfoType.list_reload,
            value: {
              id,
            },
          };
        });
      },
      confirm(
        text: string,
        event: ITrigger,
        config?: { okText?: string; width?: number; closable?: boolean }
      ) {
        const { okText, width, closable = true } = config || {};
        set((state) => {
          state.messageInfo = {
            type: EInfoType.confirm_normal,
            value: {
              text,
              event,
              okText,
              width,
              closable,
            },
          };
        });
      },
      formUpdate(id: number, data: any) {
        set((state) => {
          state.messageInfo = {
            type: EInfoType.form_update,
            value: {
              id,
              data,
            },
          };
        });
      },
      createEvent(id: number) {
        set((state) => {
          state.messageInfo = {
            type: EInfoType.create_resource,
            value: {
              id,
            },
          };
        });
      },
      updateOnce(id: number, data: any) {
        set((state) => {
          state.messageInfo = {
            type: EInfoType.once_update,
            value: {
              id,
              data,
            },
          };
        });
      },
    }))
  )
);
export default useMessageServiceStore;
