import SelectP from "@/common/form/SelectP";
import { ISelectItem } from "@/common/interface";
import { FC, useCallback, useRef } from "react";
interface ITableHeaderQueryDataBySelect {
  title: string;
  data: ISelectItem[] | string[] | number[];
  getData: any;
}
const TableHeaderQueryDataBySelect: FC<ITableHeaderQueryDataBySelect> = ({
  title,
  getData,
  data,
}) => {
  const { current: change } = useRef(getData);
  const handleChage = useCallback(
    (e: any) => {
      change?.(e);
    },
    [change]
  );

  return (
    <div style={{ width: "100%" }}>
      <p
        style={{
          color: "#9B9EAC",
          marginBottom: 8,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {title}
      </p>
      <SelectP
        onChange={handleChage}
        style={{ width: "100%" }}
        data={data}
        placeholder={`Select ${title}`}
        allowClear
      ></SelectP>
    </div>
  );
};

export default TableHeaderQueryDataBySelect;
