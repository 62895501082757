import { useEffect } from "react";
import { ICallback } from "@/common/interface";
import messageServiceStore from "@/store/messageServiceStore";
import EInfoType from "@/store/infoType";

const useSubscribe = <T>(actionType: EInfoType, load: ICallback<T>) => {
  useEffect(() => {
    const unsubscribe = messageServiceStore.subscribe(
      ({ messageInfo }: any) => {
        const latestMessage = messageInfo;
        if (latestMessage && actionType === latestMessage.type) {
          const _value = latestMessage.value as T;
          load(_value);
        }
      }
    );
    return () => unsubscribe();
  }, [load, actionType]);
};

export default useSubscribe;
