import playbutton from "@/assets/mute.png";
import { Col, Dropdown, MenuProps, Row } from "antd";
import HLS from "hls.js";
// import { default as Mpegts, default as NativePlayer } from "mpegts.js";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import "./index.less";
interface IVideoPlayer {
  source: string;
  chase?: boolean;
  closeVideo: () => void;
}

const VideoPlayer: FC<IVideoPlayer> = ({ source, closeVideo }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { current: _closeVideo } = useRef(closeVideo);
  const [iconBoxVisible, setIconBoxVisible] = useState(true);
  const hlsPlayer = useRef<HLS | null>(null);
  // const mpegtsPlayer = useRef<Mpegts.Player | NativePlayer.Player | any>(null);
  const mpegtsPlayer = useRef<any>(null);
  const lastTimeRef = useRef(0); // 上一个时间点
  const lastFrameCountRef = useRef(0); // 上一个时间点解析的帧数
  // const chaseTimer = useRef<number>();
  const [hasPausedOnce, setHasPausedOnce] = useState(false); // 是否已经暂停过一次
  const [isFirstPlayAfterPause, setIsFirstPlayAfterPause] = useState(false); // 是否是第一次暂停后的第一次播放

  const handleIconBoxClick = () => {
    setIconBoxVisible(false);
    if (videoRef.current) {
      videoRef.current.muted = false;
    }
  };
  const [decodedFrames, setDecodedFrames] = useState<string>("");
  const [speed, setSpeed] = useState<any>("");
  const [bufferHealth, setBufferHealth] = useState<any>("");
  const [frameRate, setFrameRate] = useState<any>(0); // 存储每秒帧率
  const [audioSpeed, setAudioSpeed] = useState<any>("");
  const [videoSpeed, setVideoSpeed] = useState<any>("");
  //暂时注释
  // const [audioHost, setAudioHost] = useState<any>("");
  // const [videoHost, setVideoHost] = useState<any>("");

  const [droppedFrames, setDroppedFrames] = useState<any>("");
  const [segments, setSegments] = useState<any>("");
  const [audioDataRate, setAudioDataRate] = useState<any>("");
  const [videoDataRate, setVideoDataRate] = useState<any>("");
  const [resolution, setResolution] = useState<any>("");
  const [mimeType, setMimeType] = useState<any>("");
  const [playerType, setPlayerType] = useState<any>("");
  const [openViewParams, setOpenViewParams] = useState<boolean>(false);

  const initializeHLS = useCallback(
    (videoElement: HTMLVideoElement, playUrl: string) => {
      try {
        const hls = new HLS({
          debug: false,
        });
        hls.attachMedia(videoElement);
        hls.on(HLS.Events.MEDIA_ATTACHED, () => {
          hls.loadSource(playUrl);
        });
        hls.on(HLS.Events.MANIFEST_PARSED, () => {
          (videoElement.play() as Promise<void>).catch((err) =>
            console.error("HLS play error:", err)
          );
        });
        hls.on(HLS.Events.ERROR, (event, data) => {
          if (data.fatal) {
            switch (data.type) {
              case HLS.ErrorTypes.NETWORK_ERROR:
                hls.startLoad();
                break;
              case HLS.ErrorTypes.MEDIA_ERROR:
                hls.recoverMediaError();
                break;
              default:
                hls.destroy();
                break;
            }
          }
        });
        hlsPlayer.current = hls;
      } catch (error) {
        console.error("HLS initialization error:", error);
      }
    },
    []
  );

  const listenerError = useCallback((e: any) => {
    console.log("Mpegts player error:", e);
  }, []);
  const listenerLoading = useCallback((e: any) => {
    console.log("play loading:", e);
  }, []);

  //暂时注释
  // const extractPath = (url: string) => {
  //   // 去掉协议部分
  //   const noProtocol = url.replace(/^(https?:\/\/)/, "");
  //   // 找到第一个问号的位置并截取
  //   const path = noProtocol.split("?")[0];
  //   return path;
  // };

  const listenerMediaInfo = useCallback((stats: any) => {
    // console.log("MediaInfo:",stats);
    const mimeType = stats && "mimeType" in stats ? stats.mimeType : "Unknown";
    setMimeType(mimeType);
    const resolution = `${stats.width}x${stats.height}`;
    setResolution(resolution);
    const videoDataRate =
      stats && "videoDataRate" in stats
        ? `${stats.videoDataRate} Kbps`
        : "Unknown";
    setVideoDataRate(videoDataRate);
    const audioDataRate =
      stats && "audioDataRate" in stats
        ? `${stats.audioDataRate} Kbps`
        : "Unknown";
    setAudioDataRate(audioDataRate);
  }, []);

  const listenerStatisticsInfo = useCallback((stats: any) => {
    // console.log("StatisticsInfo:",stats);
    const videoElement = videoRef.current as HTMLVideoElement;
    const playerType =
      stats && "playerType" in stats ? stats.playerType : "Unknown";
    setPlayerType(playerType);

    const segments = stats && "segments" in stats ? stats.segments : "Unknown";
    setSegments(segments);
    const droppedFrames =
      stats && "droppedFrames" in stats ? stats.droppedFrames : "Unknown";
    setDroppedFrames(droppedFrames);

    //暂时注释
    // const videoHost = extractPath(stats.url);
    // setVideoHost(videoHost);
    // const audioHost = videoHost;
    // setAudioHost(audioHost);

    const videoSpeed = videoElement.playbackRate || 1;
    setVideoSpeed(videoSpeed);
    const audioSpeed = videoSpeed; // 通常视频和音频速度一致
    setAudioSpeed(audioSpeed);
    const speed = stats && "speed" in stats ? `${stats.speed} KB/s` : "Unknown";
    setSpeed(speed);
    const decodedFrames =
      stats && "decodedFrames" in stats ? `${stats.decodedFrames}` : "Unknown";
    setDecodedFrames(decodedFrames);

    const buffered = stats?.buffered;
    const bufferHealth =
      buffered.length > 0
        ? buffered.end(buffered.length - 1) - stats?.currentTime
        : 0;
    setBufferHealth(`${bufferHealth.toFixed(2)} s`);

    const currentTime = stats?.currentTime ?? 0;
    const currentFrameCount = stats?.decodedFrames ?? 0;

    // 如果当前秒数变化了，计算本秒新增的帧数
    if (Math.floor(currentTime) !== Math.floor(lastTimeRef.current)) {
      // 计算这一秒新增的帧数
      const framesThisSecond = currentFrameCount - lastFrameCountRef.current;

      // 如果当前秒新增帧数为负，说明帧数数据错误，设置为 0
      const validFramesThisSecond = framesThisSecond < 0 ? 0 : framesThisSecond;

      setFrameRate(validFramesThisSecond); // 更新当前秒解析的新增帧数

      // 更新上一个时间和帧数
      lastTimeRef.current = currentTime;
      lastFrameCountRef.current = currentFrameCount;
    } else {
      // 如果当前秒没有变化，则帧数置为 0
      setFrameRate(0);
    }
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const player = mpegtsPlayer.current;
      if (player) {
        // 手动获取统计信息
        const stats = player.statisticsInfo;

        if (stats) {
          listenerStatisticsInfo({
            ...stats,
            buffered: player.buffered,
            currentTime: player.currentTime,
          });
        }

        // 手动获取媒体信息
        const mediaInfo = player.mediaInfo;
        if (mediaInfo) {
          listenerMediaInfo(mediaInfo);
        }
      }
    }, 1000); // 每隔1秒获取一次

    return () => {
      clearInterval(intervalId); // 清除定时器
    };
  }, [listenerStatisticsInfo, listenerMediaInfo]);
  const initializeMpegts = useCallback(
    (videoElement: HTMLVideoElement, playUrl: string) => {
      try {
        if (window.mpegts.getFeatureList().mseLivePlayback) {
          const player = window.mpegts.createPlayer(
            {
              type: "flv",
              isLive: true,
              url: playUrl,
            },
            {
              enableStashBuffer: false,
              isLive: true,
              liveSync: true,
              // liveBufferLatencyChasing: true,
              // liveBufferLatencyMaxLatency: 1, // 最大缓存时间
              // liveBufferLatencyMinRemain: 0.5, // 最小缓存时间

              // reuseRedirectedURL: true,
              // lazyLoad: false,
              // deferLoadAfterSourceOpen: false,
              // autoCleanupSourceBuffer: true,
            }
          );
          player.on(window.mpegts.Events.ERROR, listenerError);
          player.on(window.mpegts.Events.LOADING_COMPLETE, listenerLoading);

          player.attachMediaElement(videoElement);
          player.load();
          (player.play() as Promise<void>).catch((err) => {
            console.error("Mpegts play error:", err);
          });
          mpegtsPlayer.current = player;
        } else {
          console.error("MPEG-TS is not supported in this browser.");
        }
      } catch (error) {
        console.error("Mpegts initialization error:", error);
      }
    },
    [listenerError, listenerLoading] //
  );

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      const playUrl = source.split("?")[0];
      const _playUrl = splitString(playUrl);
      try {
        if (_playUrl.endsWith(".m3u8") && HLS.isSupported()) {
          initializeHLS(videoElement, source);
        } else if (
          _playUrl.endsWith(".flv") &&
          window.mpegts.getFeatureList().mseLivePlayback
        ) {
          initializeMpegts(videoElement, source);
        }

        // if (chase) {
        //   setupChaseMode(videoElement);
        // }
      } catch (error) {
        console.error("Video setup error:", error);
      }
    }

    return () => {
      try {
        hlsPlayer.current?.destroy();
        mpegtsPlayer.current?.detachMediaElement();
        mpegtsPlayer.current?.destroy();
      } catch (error) {
        console.error("Cleanup error:", error);
      }
    };
  }, [source, initializeHLS, initializeMpegts]);

  useEffect(() => {
    const videoElement = videoRef.current;
    let isClickPause = false; // 用于标记是否是点击触发的暂停

    if (videoElement) {
      // 监听 click 事件
      const handleClick = () => {
        // 如果是点击视频区域，可能是暂停操作
        isClickPause = true;
      };

      // 监听 pause 事件
      const handlePause = () => {
        // 只有点击的暂停才算
        if (isClickPause) {
          if (!hasPausedOnce) {
            // 第一次暂停
            setHasPausedOnce(true); // 标记为已经暂停过
            setIsFirstPlayAfterPause(true); // 标记为需要在下次播放时取消静音
          }
        }

        // 重置点击标记
        isClickPause = false;
      };

      // 监听 play 事件
      const handlePlay = () => {
        if (isFirstPlayAfterPause && iconBoxVisible) {
          videoElement.muted = false; // 取消静音
          setIconBoxVisible(false); // 隐藏播放按钮
          setIsFirstPlayAfterPause(false); // 重置标记
        }
      };

      videoElement.addEventListener("click", handleClick);
      videoElement.addEventListener("pause", handlePause);
      videoElement.addEventListener("play", handlePlay);

      // 清除事件监听
      return () => {
        videoElement.removeEventListener("click", handleClick);
        videoElement.removeEventListener("pause", handlePause);
        videoElement.removeEventListener("play", handlePlay);
      };
    }
  }, [hasPausedOnce, isFirstPlayAfterPause, iconBoxVisible]);

  const handleVolumeChange = () => {
    if (videoRef.current) {
      const isMuted = videoRef.current.muted;
      const currentVolume = videoRef.current.volume;

      if (!isMuted && currentVolume > 0) {
        // console.log("用户取消了静音，当前音量:", currentVolume);
        setIconBoxVisible(false);
      }
    }
  };
  const items: MenuProps["items"] = [
    {
      label: "Statistics",
      key: "1",
      onClick: () => {
        setOpenViewParams(true);
      },
    },
    {
      label: "Close video",
      key: "2",
      onClick: _closeVideo,
    },
  ];

  return (
    <div className="live-video-play-box">
      <Dropdown arrow={false} menu={{ items }} trigger={["contextMenu"]}>
        <video
          ref={videoRef}
          style={{ width: "100%", height: "100%", objectFit: "fill" }}
          controls
          muted
          preload="none"
          autoPlay
          id={"liveVideoId"}
          onVolumeChange={handleVolumeChange}
        />
      </Dropdown>
      {iconBoxVisible && (
        <div className="live-video-play-icon-box" onClick={handleIconBoxClick}>
          {/* <NotificationOutlined className="live-video-play-icon" size={20} /> */}
          <img src={playbutton} alt="play" className="live-video-play-img" />
        </div>
      )}
      {openViewParams && (
        <div className="player-params">
          <div
            className="player-params-close"
            onClick={() => {
              setOpenViewParams(false);
            }}
          >
            X
          </div>
          <Row>
            <Col span={24}>
              <p>
                <span>Mime Type</span>:<span>{mimeType}</span>
              </p>
            </Col>
            {/* //暂时注释 */}
            {/* <Col span={24}>
              <p>
                <span>Video Host</span>:<span>{videoHost}</span>
              </p>
            </Col>
            <Col span={24}>
              <p>
                <span>Audio Host</span>:<span>{audioHost}</span>
              </p>
            </Col> */}
          </Row>
          <Row>
            <Col span={12}>
              <p>
                <span>Player Type</span>:<span>{playerType}</span>
              </p>
            </Col>
            <Col span={12}>
              <p>
                <span>Resolution</span>:<span>{resolution}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <p>
                <span>Video DataRate</span>:<span>{videoDataRate}</span>
              </p>
            </Col>
            <Col span={12}>
              <p>
                <span>Audio DataRate</span>:<span>{audioDataRate}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <p>
                <span>Segments</span>:<span>{segments}</span>
              </p>
            </Col>
            <Col span={12}>
              <p>
                <span>Dropped Frames</span>:<span>{droppedFrames}</span>
              </p>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <p>
                <span>Video Speed</span>:<span>{videoSpeed}</span>
              </p>
            </Col>
            <Col span={12}>
              <p>
                <span>Audio Speed</span>:<span>{audioSpeed}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <p>
                <span>Speed</span>:<span>{speed}</span>
              </p>
            </Col>
            <Col span={12}>
              <p>
                <span>Decoded frames</span>:<span>{decodedFrames}</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <p>
                <span>Buffer Health</span>:<span>{bufferHealth}</span>
              </p>
            </Col>
            <Col span={12}>
              <p>
                <span>Frames per second</span>:<span>{frameRate}</span>
              </p>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
};

export default VideoPlayer;

const splitString = (str: string) => {
  if (str.includes("?")) {
    return str.split("?")[0];
  } else {
    return str;
  }
};
