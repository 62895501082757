import excelAPI from "@/api/apis/excel";
import roleAPI from "@/api/apis/role";
import userAPI from "@/api/apis/user";
import { ISelectItem } from "@/common/interface";
import Template from "@/common/template";
import { filterEmptyProperties } from "@/common/utils/untils";
import SelectorDataFromTo from "@/components/SelectorDataFromTo";
import TableHeaderQueryData from "@/components/TableHeaderQueryData";
import useEventId from "@/hooks/useEventId";
import { FC, SetStateAction, useCallback, useEffect, useState } from "react";
import SelectP from "@/common/form/SelectP";
import dayjs from "dayjs";
import BreadcrumbPlx from "@/common/breadcrumbPlx";
import { exportTableToPDF } from "./pdfHtml";
import { handleTimeFormat } from "@/common/utils/untilsX";

const AuditLogsUsersList: FC = () => {
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const templateId = useEventId();
  const [roleSelectData, setRoleSelectData] = useState<any>([]);
  const queryData = (data: any) => {
    return userAPI.getUserPage(data);
  };
  const change = useCallback(
    (e: {
      startTime: SetStateAction<string>;
      endTime: SetStateAction<string>;
    }) => {
      setStartTime(e.startTime);
      setEndTime(e.endTime);
    },
    []
  );
  const columns = [
    {
      key: "name",
      title: <TableHeaderQueryData title="Name" getData={setName} />,
      dataIndex: "name",
    },
    {
      key: "user_role",
      title: (
        <div style={{ width: "100%" }}>
          <p
            style={{
              color: "#9B9EAC",
              marginBottom: 8,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            Role
          </p>
          <SelectP
            onChange={setRole}
            style={{ width: "100%" }}
            data={roleSelectData}
            placeholder={`Select Role`}
            allowClear
          ></SelectP>
        </div>
      ),
      dataIndex: "user_role",
      width: "33%",
    },
    {
      key: "email",
      title: <TableHeaderQueryData title="Email" getData={setEmail} />,
      dataIndex: "email",
    },
  ];
  const getRoleSelectData = useCallback(async () => {
    const res = await roleAPI.getRoleSelect();
    const _list: ISelectItem[] = [];
    if (res) {
      for (const key in res) {
        if (Reflect.hasOwnProperty.call(res, key)) {
          _list.push({
            id: res[key],
            name: key,
          });
        }
      }
    }
    setRoleSelectData(_list);
  }, []);

  useEffect(() => {
    getRoleSelectData();

    return () => {};
  }, [getRoleSelectData]);
  const exportEvent = useCallback((data: any) => {
    return excelAPI.userExportExcel(data);
  }, []);
  const exportEventPdf = useCallback(async (data: any) => {
    const res = await userAPI.getUserPage({ ...data, operate_log: 1 });
    const timeStr = `${
      handleTimeFormat(data.start_date, "DD MMM YYYY", false) || res.min_time
    } - ${
      handleTimeFormat(data.end_date, "DD MMM YYYY", false) || res.max_time
    }`;
    return exportTableToPDF({ ...res, heading4: timeStr });
  }, []);

  return (
    <section>
      <div className="audit-logs-box">
        <BreadcrumbPlx
          items={[
            "Audit Logs Users",
            `List of authorized users as of ${dayjs().format("MMMM D, YYYY")}`,
          ]}
        />
        <SelectorDataFromTo onChange={change} />
        <div className="bg-white" style={{ marginTop: 15 }}>
          <Template
            id={templateId}
            queryData={queryData}
            rowKey={"id"}
            config={columns}
            exportConfig={{
              queryApi: exportEvent,
              fileName: "user_record_logs",
              pdfQueryApi: exportEventPdf,
            }}
            // showFooter={false}
            moreParams={filterEmptyProperties({
              name,
              role_id: role,
              email,
              start_date: startTime,
              end_date: endTime,
            })}
          />
        </div>
      </div>
    </section>
  );
};

export default AuditLogsUsersList;
