interface TableData {
    name: string;
    user_role: string;
    email: string;
}

interface JsonData<T> {
    heading1: string;
    heading2: string;
    heading3: string;
    heading4: string;
    data: T[];
}

export const exportTableToPDF = <T extends TableData>(
    jsonData: JsonData<T>
) => {
    let table = `
        <div id="container" style="display: flex; align-items: center; flex-direction: column; color: #000000;">
            <h1 style="all: unset; font-size: 24px; font-weight: bold;">
            ${jsonData.heading1}
            </h1>
            <p style="all: unset; font-size: 16px; font-weight: 550; line-height: 24px;">
            ${jsonData.heading2}
            </p>
            <p style="all: unset; font-size: 16px; font-weight: 550; line-height: 24px;">
            ${jsonData.heading3}
            </p>
            <p style="all: unset; font-size: 16px; font-weight: 550; line-height: 24px; color: red;">
            ${jsonData.heading4}
            </p>
            <table style="width: 100%; margin-top: 20px; border-spacing: 0; table-layout: fixed;">
                <thead style="background-color: #2d3f56; color: #ffffff; font-size: 14px; line-height: 28px;">
                    <tr>
                        <th style="padding: 8px; text-align: center; font-weight: normal; word-wrap: break-word; white-space: normal; vertical-align: top;">${'Name'}</th>
                        <th style="padding: 8px; text-align: center; font-weight: normal; word-wrap: break-word; white-space: normal; vertical-align: top;">${'Role'}</th>
                        <th style="padding: 8px; text-align: center; font-weight: normal; word-wrap: break-word; white-space: normal; vertical-align: top;">${'Email'}</th>
                    </tr>
                </thead>
                <tbody>
                ${jsonData.data
                .map(
                    (item, idx) => `
                    <tr style="font-size: 12px; line-height: 28px; background-color: ${
                        idx % 2 === 0 ? "#f3f4f5" : "#ffffff"
                    };page-break-inside: avoid;">
                        <td style="padding: 8px; text-align: left; word-wrap: break-word; white-space: normal; vertical-align: top;">
                        ${item.name}
                        </td>
                        <td style="padding: 8px; text-align: left; word-wrap: break-word; white-space: normal; vertical-align: top;">
                        ${item.user_role}
                        </td>
                        <td style="padding: 8px; text-align: left; word-wrap: break-word; white-space: normal; vertical-align: top;">
                        ${item.email}
                        </td>
                    </tr>`
                )
                .join("")}
                </tbody>
            </table>
        </div>`;
    return table;
};
