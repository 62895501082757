import { BaseAPI } from "../base/baseApi";

export class ExcelAPI extends BaseAPI {
  //流水记录excel导出
  async subscriptionFinanceExportExcel(data: ISubscriptionExportExcel) {
    return this.get("/subscriber/finance/excel", data, {
      responseType: "blob",
    });
  }

  //流水记录pdf导出
  async subscriptionFinanceExportPdf(data: ISubscriptionExportExcel) {
    return this.get("/subscriber/finance/pdf", data, {
      responseType: "blob",
    });
  }

  //用户记录excel导出
  async userExportExcel(data: IUserExportExcel) {
    return this.get("/user/export/excel", data, {
      responseType: "blob",
    });
  }
  //用户记录pdf导出
  async userExportPdf(data: IUserExportExcel) {
    return this.get("/user/export/pdf", data, {
      responseType: "blob",
    });
  }

  //订阅记录excel导出
  async subscriberExportExcel(data: ISubscriberExportExcel) {
    return this.get("/subscriber/export/excel", data, {
      responseType: "blob",
    });
  }

  //订阅记录excel导出
  async subscriberTerminatedExportExcel(data: ISubscriberExportExcel) {
    return this.get("/subscriber/terminated/excel", data, {
      responseType: "blob",
    });
  }

  //订阅记录terminated pdf导出
  async subscriberTerminatedExportPdf(data: ISubscriberExportExcel) {
    return this.get("/subscriber/terminated/pdf", data, {
      responseType: "blob",
    });
  }

  //订阅记录valid pdf导出
  async subscriberVolidExportPdf(data: ISubscriberExportExcel) {
    return this.get("/subscriber/export/pdf", data, {
      responseType: "blob",
    });
  }

  //操作日志记录导出为excel
  async operateLogExportExcel(data: IOperateLogExportExcel) {
    return this.get("/audit/export/excel", data, {
      responseType: "blob",
    });
  }

  async auditExportPdf(data: IOperateLogExportExcel) {
    return this.get("/audit/export/pdf", data, {
      responseType: "blob",
    });
  }
}

const excelAPI = new ExcelAPI();
export default excelAPI;

export interface ISubscriptionExportExcel {
  start_date?: string;
  end_date?: string;
  request_date?: string;
  activation_date?: string;
  termination_date?: string;
  billing_cycle: number;
  transaction_id: number;
  amount: number;
}

export interface IUserExportExcel {
  start_date?: string;
  end_date?: string;
  role_id: number;
  name?: string;
  email?: string;
}

export interface ISubscriberExportExcel {
  account_number: string;
  start_date: string;
  end_date: string;
  request_date: string;
  activation_date: string;
  termination_date: string;
  billing_cycle: string;
  termination_initiated: string;
  termination_by: string;
  subcription_package: string;
  termination_by_name: string;
}

export interface IOperateLogExportExcel {
  end_date?: string;
  start_date?: string;
  type: number; //user-1  role-2 subscriptionrate -3 subscription-4
  event?: string; //1-create 2-update
  target?: string;
  old_values?: string;
  new_values?: string;
  username?: string;
  ip_address?: string;
  user_agent?: string;
  operate_date?: string;
}
