import { Form, Input, InputNumber, Layout, notification } from "antd";
import { FC, useEffect, useState, useCallback } from "react";
import "./index.less";
import FormS from "@/common/form/FormS";
import useMessageServiceStore from "@/store/messageServiceStore";
import { useLocation, useNavigate } from "react-router-dom";
import useEventId from "@/hooks/useEventId";
import ValidDataTime from "./components/ValidDataTimePlx";
import CustomRadio from "@/components/customRadio";
import subscriptionAPI from "@/api/apis/subscription";
import dayjs from "dayjs";
import BreadcrumbPlx from "@/common/breadcrumbPlx";
import { ensureFullDateTime } from "@/common/utils/untils";
const NewSubscription: FC = () => {
  const formId = useEventId();
  const { formUpdate } = useMessageServiceStore();
  const navigate = useNavigate();
  const [hidePwd, setHidePwd] = useState<boolean>(false);
  const location = useLocation();
  useEffect(() => {
    const data: any = location.state;
    if (data) {
      console.log(data?.start_date_time);

      const _data = {
        id: data.id,
        package: data?.package,
        amount: data?.amount,
        start_date_time: dayjs(data?.valid_from).format("YYYY-MM-DD"),
        end_date_time: dayjs(data?.valid_to).format("YYYY-MM-DD"),
        default: data?.default,
        mode: "edit",
      };
      formUpdate(formId, _data);
      setHidePwd(true);
    }
  }, [location, formId, formUpdate]);

  const submit = useCallback(
    async (data: any) => {
      const _data = {
        ...data,
        start_date_time: ensureFullDateTime(data.start_date_time, "start"),
        end_date_time: ensureFullDateTime(data.end_date_time, "end"),
      };
      try {
        if (_data?.mode === "edit") {
          delete _data.mode;
          await subscriptionAPI.updateSubscription(_data);
          notification.success({
            message: "Update success",
          });
        } else {
          // 新增
          await subscriptionAPI.addSubscription(_data);
          notification.success({
            message: "Add success",
          });
        }
        navigate("/admin/subscription_rates");
      } catch (error) {
        console.error(error);
      }
    },
    [navigate]
  );
  return (
    <Layout>
      <BreadcrumbPlx
        items={["Subscription", location.state ? "update" : "new"]}
      />
      <FormS
        id={formId}
        footerBtnConfig={{ okText: hidePwd ? "Edit" : "Add" }}
        submitEvent={submit}
        layout="vertical"
      >
        <main className="page-container subscriber-new-main">
          <div style={{ width: 500 }}>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Subscription package cannot be empty!",
                },
              ]}
              name="package"
              label="Subscription package"
            >
              <Input />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Amount cannot be empty!",
                },
              ]}
              name="amount"
              label="Amount"
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item noStyle>
              <ValidDataTime />
            </Form.Item>
            <Form.Item name="default">
              <CustomRadio text={"Default"} true={"Y"} false={"N"} />
            </Form.Item>
          </div>
        </main>
      </FormS>
    </Layout>
  );
};

export default NewSubscription;
