import { ICustomizeCompProps } from "@/common/interface";
import { Radio } from "antd";
import { FC, useCallback, useEffect, useRef, useState } from "react";
interface IProps {
  text?: string;
  true: string | number | boolean;
  false: string | number | boolean;
  disabled?: boolean;
  defaultChecked?: boolean;
}
const CustomRadio: FC<ICustomizeCompProps & IProps> = (props) => {
  const { onChange, value, text } = props;
  const [check, setCheck] = useState(value || 0);
  const { current: change } = useRef(onChange);
  useEffect(() => {
    setCheck(value);
  }, [value]);
  useEffect(() => {
    if (props.defaultChecked) {
      setCheck(props.true);
    }
  }, [props.defaultChecked, props.true]);

  const handleRadioClick = useCallback(() => {
    if (check === props.true) {
      setCheck(props.false);
      change?.(props.false);
    } else {
      setCheck(props.true);
      change?.(props.true);
    }
  }, [change, check, props.false, props.true]);

  return (
    <Radio
      onClick={props.disabled ? () => {} : handleRadioClick}
      checked={check === props.true}
    >
      {text}
    </Radio>
  );
};

export default CustomRadio;
