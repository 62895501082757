import { Layout, Spin } from "antd";
import { FC } from "react";

const LoadingPlx: FC = () => {
  return (
    <Layout className="comp-load-context">
      <Spin
        size="large"
        style={{ position: "fixed", top: "40%", left: "50%" }}
      />
    </Layout>
  );
};
export default LoadingPlx;
