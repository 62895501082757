import meetingAPI from "@/api/apis/meetings";
import VideoPlayer from "@/components/VideoPlayer";
// import useIsWindows from "@/hooks/useIsWindows";
import IconFont from "@/common/IconFont";
import { Col, Layout, Row } from "antd";
import { FC, useEffect, useState } from "react";
import "./index.less";
const Dashboard: FC = () => {
  const [venues, setVenues] = useState<any>({});
  const [count, setCount] = useState<any>({});

  useEffect(() => {
    meetingAPI.meetingMonitor().then((res) => {
      setVenues(res.venues);
      const data = {
        ...res,
      };
      delete data.venues;
      setCount(data);
    });
  }, []);
  const closeVideo = (url: string) => {
    setVenues((prVenues: any) =>
      Object.fromEntries(
        Object.entries(prVenues).filter(([key]) => key !== url)
      )
    );
  };
  return (
    <Layout style={{ minHeight: "100%" }}>
      <Row className="header-container-data" gutter={[20, 20]}>
        <Col sm={24} md={12} lg={6}>
          <div className="col-content">
            <p className="total-title">Total Enabled Meetings</p>
            <Row align={"middle"} justify={"space-between"}>
              <Col>
                <IconFont type="icon-a-Group3572" style={{ fontSize: 44 }} />
              </Col>
              <Col>
                <div className="my-icon-and-total-text icon-and-total-box1">
                  {count?.enabled_meeting_count}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col sm={24} md={12} lg={6}>
          <div className="col-content">
            <p className="total-title">Total Access Tokens</p>
            <Row align={"middle"} justify={"space-between"}>
              <Col>
                <IconFont type="icon-a-Group35721" style={{ fontSize: 44 }} />
              </Col>
              <Col>
                <div className="my-icon-and-total-text icon-and-total-box2">
                  {count?.access_token_count}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col sm={24} md={12} lg={6}>
          <div className="col-content">
            <p className="total-title">Total Access Subscriptions</p>
            <Row align={"middle"} justify={"space-between"}>
              <Col>
                <IconFont type="icon-a-Group35722" style={{ fontSize: 44 }} />
              </Col>
              <Col>
                <div className="my-icon-and-total-text icon-and-total-box3">
                  {count?.access_subscription_count}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
        <Col sm={24} md={12} lg={6}>
          <div className="col-content">
            <p className="total-title">Total Blocked Meetings</p>
            <Row align={"middle"} justify={"space-between"}>
              <Col>
                <IconFont type="icon-a-Group35723" style={{ fontSize: 44 }} />
              </Col>
              <Col>
                <div className="my-icon-and-total-text icon-and-total-box4">
                  {count?.blocked_meeting_count}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div className="currently-assigned-venues-box">
        <div className="currently-assigned-venues-container">
          <h1>Currently Assigned Venues</h1>
          <Row gutter={[30, 20]}>
            {Object.keys(venues)?.map((key) => (
              <Col sm={24} md={24} lg={8} key={key}>
                <p className="venues-bus-route-title">{key}</p>
                <div className="venues-bus-route-box">
                  <VideoPlayer
                    closeVideo={() => closeVideo(key)}
                    source={venues[key]}
                  ></VideoPlayer>
                  {/* <VideoPlayer source={"https://live.cloud24j.com/live/venue3uat.flv?auth_key=MAwykfMhqOJQzukkB6nhr"}></VideoPlayer> */}
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
