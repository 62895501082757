import {ICallback, IchildrenProps} from "@/common/interface";
import {Button, Col, Input, Row, Space} from "antd";
import React, {
    FC,
    ReactNode,
    useCallback,
    useMemo,
    useState,
} from "react";
import "./index.less";

const CustomSearch: FC<IProps> = (props) => {
    const [keyword, setKeyWord] = useState<string>();
    const {name, placeholder, keyName, submit: submitProps} = props;
    const fmtKey = useMemo(() => {
        return keyName || "keyword";
    }, [keyName]);
    // 提交搜索
    const submit = useCallback(() => {
        submitProps &&
        submitProps({
            [fmtKey]: keyword,
        });
    }, [submitProps, fmtKey, keyword]);

    const changeHandler = useCallback(
        (e: { target: { value: React.SetStateAction<any> } }) => {
            setKeyWord(e.target.value);
        },
        []
    );

    const reset = useCallback(() => {
        submitProps &&
        submitProps({
            [fmtKey]: "",
        });
        setKeyWord("");
    }, [submitProps, fmtKey]);

    return (
        <div className="comp-search">
            <Row gutter={[15, 15]}>
                {!props.hiddenSearch && (
                    <>
                        <Col>
                            <Input
                                value={keyword}
                                onChange={changeHandler}
                                className="glob-keyword-input"
                                autoComplete="off"
                                placeholder={placeholder || "Search"}
                                name={name}
                                style={{minWidth: 300}}
                                onPressEnter={submit}
                            />
                        </Col>
                        <Col>
                            <Space>
                                <Button type="primary" onClick={submit}>
                                    Submit
                                </Button>
                                <Button onClick={reset}>
                                    Reset
                                </Button>
                            </Space>
                        </Col>
                    </>
                )}
                {props.otherBtns &&
                    props.otherBtns.map((btn: ReactNode, idx: React.Key) => {
                        return <Col key={idx}>{btn}</Col>;
                    })}
            </Row>
        </div>
    );
};

interface IBasicProps {
    placeholder?: string;
    needIntl?: boolean;
    submit: ICallback<any>;
    buttonName: string;
    /** 查询字段 */
    keyName: string;
    /** 其他按钮 */
    otherBtns?: React.ReactNode[];
    /** 隐藏搜索输入部分 */
    hiddenSearch?: boolean;
}

interface IFouStyle {
    forceStyle?: {
        size: "small" | "large";
        fixHeight?: number;
    };
}

interface ITypeInput {
    className?: string;
    name?: string;
}

type IProps = ITypeInput & IBasicProps & IFouStyle & IchildrenProps;

export default CustomSearch;
