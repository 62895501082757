import LayoutContainer from "@/common/layout";
import NotFoundPage404 from "@/pages/Errors/404";
import NotFoundPage403 from "@/pages/Errors/403";
import Login from "@/pages/login";
import {commonRouter, routerList} from "./menu";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import RouterAuth, {
  RedirectIfAuthenticated,
  RedirectIfAuthenticated2Fa,
} from "./routerAuth";
import Authentication from "@/pages/authentication";
import { FC, useEffect, useState } from "react";
import useStore from "@/store/store";
import { filterRoutes } from "@/common/utils/untils";
import { IRouteObject } from "@/common/interface";
import LoadingPlx from "@/common/LoadingPlx/LoadingPlx";
import AuthenticationManagement from "@/pages/authenticationManagement/index";

const AppRouter: FC = () => {
  const { userInfo } = useStore();
  const [router, setRouter] = useState<any>(null);

  const getRoutes = (access_module_codes: any) => {
    let newList: IRouteObject[] = [];

    if (access_module_codes?.length) {
      const list = filterRoutes(routerList, access_module_codes);
      newList = list;
      // common router
      newList.unshift(commonRouter);
      const defaultRoute =
        list[0]["index"] === true ? "" : list[0]["path"] || "/admin";
      defaultRoute &&
        newList.unshift({
          element: <Navigate to={"/admin"} />,
          index: true,
        });
    }
    if (!newList.some((item) => item.path === "register2fa")) {
      newList.push({
        path: "register2fa",
        code: "AUTHENTICATION_MANAGEMENT",
        element: (
          <RouterAuth>
            <AuthenticationManagement />
          </RouterAuth>
        ),
      });
    }

    return [
      {
        path: "/",
        element: (
          <RouterAuth>
            <Navigate to="/admin" />
          </RouterAuth>
        ),
      },
      {
        path: "/admin",
        element: (
          <RouterAuth>
            <LayoutContainer />
          </RouterAuth>
        ),
        children: newList,
      },
      {
        path: "/login",
        element: (
          <RedirectIfAuthenticated>
            <Login />
          </RedirectIfAuthenticated>
        ),
      },
      {
        path: "/authentication",

        element: (
          <RedirectIfAuthenticated2Fa>
            <Authentication />
          </RedirectIfAuthenticated2Fa>
        ),
      },
      {
        path: "/403",
        element: (
          <RouterAuth>
            <NotFoundPage403 />
          </RouterAuth>
        ),
      },
      {
        path: "*",
        element: (
          <RouterAuth>
            <NotFoundPage404 />
          </RouterAuth>
        ),
      },
    ];
  };
  useEffect(() => {
    setRouter(
      createBrowserRouter(getRoutes(userInfo?.access_module_codes), {})
    );
  }, [userInfo?.access_module_codes]);

  // const router = createBrowserRouter(getRoutes(), {});
  if (!router) {
    return <LoadingPlx />;
  }
  return <RouterProvider router={router} />;
};

export default AppRouter;
