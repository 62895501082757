import { notification } from "antd";
import { AxiosResponse } from "axios";
function resolveReq(rep: AxiosResponse) {
  const res: any = {
    data: null as any,
    success: false,
  };
  if (rep.status !== 200) {
    if (rep.status) {
      notification.error({
        message: rep.status,
        description: (rep.data.data as string) || "-",
      });
    } else {
      notification.error({
        message: (rep.data.data as string) || "-",
      });
    }

    return res;
  }
  if (rep.data) {
    if (rep.data.code === 200) {
      res.success = true;
      res.data = rep.data.data;
      res.code = rep.data.code;
    } else {
      notification.error({
        message: (rep.data.msg as string) || "-",
      });
    }
  } else {
    notification.error({
      message: "Network Request Error!",
      // description: "-",
    });
  }
  // if (!res.success) {
  //   console.log(rep.data);
  // }
  return res;
}

export default resolveReq;
