import { Button, Col, Form, Input, Row } from "antd";
import { FC, useRef } from "react";
import "./index.less";
import { ICallback } from "@/common/interface";

interface IProps {
    onVerify: ICallback<{ code: string }>;
}
const TwoFactorAuthentication: FC<IProps> = ({ onVerify }) => {
  const [form] = Form.useForm();
  const { current: _onVerify } = useRef(onVerify);

  return (
    <div className="login-page-right">
      <div className="login-2fa-form">
        <h2 className="login-2fa-title">Two Factor Authentication (2FA)</h2>
        <p>
          Open the two-factor authentication app on your mobile device to view
          your authentication code and verify your identity.
        </p>
        <Form
          form={form}
          initialValues={{ remember: false }}
          onFinish={_onVerify}
          autoComplete="off"
          layout="vertical"
        >
          <div style={{ padding: 10 }}>
            <Row>
              <Col md={12} sm={24} xs={24}>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "Please input your code!",
                    },
                  ]}
                  name="code"
                >
                  <Input
                    style={{ height: 32 }}
                    autoComplete="current-password"
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Row>
            <Col>
              <Form.Item>
                <Button style={{ height: 35 }} type="primary" htmlType="submit">
                  Verify
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};

export default TwoFactorAuthentication;
