import meetingAPI from "@/api/apis/meetings";
import useUILoading from "@/hooks/useLoading";
import { Layout, Radio, RadioChangeEvent, Space, Spin } from "antd";
import { FC, useCallback, useEffect, useState } from "react";
import "./index.less";
interface ISource {
  key: string;
  sort: number;
}
const SwitchOver: FC = () => {
  const [value, setValue] = useState(2);
  const [loading, open, close] = useUILoading();
  const [ips, setIps] = useState<any>([]);
  const swapSortValues = (data: ISource[]): ISource[] => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].sort === 1) {
        data[i].sort = 2;
      } else if (data[i].sort === 2) {
        data[i].sort = 1;
      }
    }
    return data;
  };
  const getSourceList = useCallback(() => {
    meetingAPI
      .sourceList()
      .then((res) => {
        setIps(() => res.source);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        close();
      });
  }, [close]);
  const seSwitchSource = useCallback(() => {
    const data: ISource[] = JSON.parse(JSON.stringify(ips));
    const _data: ISource[] = swapSortValues(data);
    meetingAPI
      .sourceSwitch(_data)
      .then(() => {
        setValue(2);
        getSourceList();
      })
      .catch((e) => {
        console.log(e);
      });
  }, [ips, getSourceList]);
  const onChange = useCallback(
    (e: RadioChangeEvent) => {
      open();
      setValue(e.target.value);
      seSwitchSource();
    },
    [open, seSwitchSource]
  );

  useEffect(() => {
    getSourceList();
    return () => {};
  }, [getSourceList]);
  return (
    <Layout>
      <Spin spinning={loading}>
        <div className="audit-logs-box">
          <h2 className="las-pages-title">
            Switch Over <span>management</span>
          </h2>
          <div className="switch-over-management">
            <p className="switch-over-management-title">AvailableIPs</p>
            <Radio.Group onChange={onChange} value={value}>
              <Space direction="vertical">
                {ips.length > 0 &&
                  ips.map((item: any) => (
                    <Radio key={item?.key} value={item?.sort}>
                      {item?.key}
                    </Radio>
                  ))}
              </Space>
            </Radio.Group>
          </div>
        </div>
      </Spin>
    </Layout>
  );
};

export default SwitchOver;
