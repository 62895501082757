enum EInfoType {
  /** 创建资源 */
  create_resource = "create_resource",
  /** 表单载入 */
  form_load = "form_load",

  /** 表单创建 */
  form_new = "form_new",

  /** 查看 */
  form_view = "form_view",

  /** 刷新列表 */
  list_reload = "list_reload",

  //修改
  form_modify = "form_modify",

  /**数据刷新或重载 */
  form_update = "form_update",

  /** 确认事件 */
  confirm_normal = "confirm_normal",

  once_update = "once_update", //单次更新
}

export default EInfoType;
