import { ISearchPage } from "@/common/template/interface";
import { BaseAPI } from "../base/baseApi";

export class ModuleAPI extends BaseAPI {
  async moduleList(data: ISearchPage) {
    return this.get("/module/list", data);
  }

  async moduleAdd(data: IModuleAddParams) {
    return this.post("/module/add", data);
  }

  async moduleUpdate(data: IModuleAddParams) {
    return this.post("/module/update", data);
  }

  async moduleDelete(params: IParamsById) {
    return this.delete("/module/delete", params);
  }
}

const moduleAPI = new ModuleAPI();
export default moduleAPI;
interface IParamsById {
  id: number;
}
export interface IModuleAddParams {
  id?: number;
  name: string;
  uri: string;
  icon: string;
  parent_id: number;
  is_child: boolean;
  route_address: string;
  sort: number;
  label: string;
}
