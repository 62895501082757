import { ICallback, ITrigger } from "@/common/interface";
import { useForm, FormInstance } from "antd/lib/form/Form";
import { useCallback } from "react";
const useFormP = (submit: ICallback<any>) => {
  const [form] = useForm();
  const submitEvent = useCallback(async () => {
    const formdata = form.getFieldsValue();
    let submitData = {};
    let originData = null;
    // 表单处理
    if (formdata.mode === "edit" && formdata.originData) {
      try {
        const oData = JSON.parse(formdata.originData);
        delete formdata.originData;
        submitData = { ...oData };
        originData = oData;
      } catch (error) {
        throw error;
      }
    }
    // 表单中的 undefined 和 null 移除掉
    Object.keys(formdata).map((key) => {
      if (formdata[key] === undefined || formdata[key] === null) {
        delete formdata[key];
      }
      return true;
    });
    submitData = { ...submitData, ...formdata };
    submit(submitData, originData);
  }, [submit, form]);

  return [form, submitEvent] as [FormInstance<any>, ITrigger];
};

export default useFormP;
