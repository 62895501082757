import { FC, useCallback, useEffect, useState } from "react";
import "./index.less";
import { Col, Empty, notification, Row, Space, Tag } from "antd";
import CustomRadio from "@/components/customRadio";
import venuesAPI from "@/api/apis/venues";
interface IGeoBlockingContentProps {
  id: number;
}
interface DataItem {
  block: 0 | 1;
  country_name: string;
}
const GeoBlockingContent: FC<IGeoBlockingContentProps> = ({ id }) => {
  const [countryList, setCountryList] = useState<DataItem[]>([]);
  const [notificationApi, contextHolder] = notification.useNotification();
  const handleGetCountryList = useCallback(async () => {
    const res = await venuesAPI.venuesCountryList({ id });
    if (res) {
      setCountryList(res);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      handleGetCountryList();
    }
  }, [id, handleGetCountryList]);
  const handeSwitchMeeting = useCallback(
    async (block: 0 | 1, country_name: string) => {
      const _data = {
        id,
        country_name,
        block,
      };
      const msg = block === 1 ? "Lock successful!" : "Unlock successful!";
      const res = await venuesAPI.venuesCountryBlock(_data);
      if (res) {
        setCountryList((originalArray: DataItem[]) =>
          originalArray.map((item) =>
            item.country_name === res.country_name ? res : item
          )
        );
        notificationApi.success({ message: msg });
      }
    },
    [id, notificationApi]
  );
  return (
    <section className="geo-block-container">
      {contextHolder}
      <div style={{ fontSize: 16, marginBottom: 20 }}>
        Check to enable Geo Block
      </div>
      {countryList?.length ? (
        countryList.map((item: any) => {
          return (
            <div
              key={item.country_name}
              className="row-btm20"
              style={{ paddingLeft: 10 }}
            >
              <Row>
                <Col flex={1}>
                  <Space>
                    <CustomRadio
                      onChange={(e) => handeSwitchMeeting(e, item.country_name)}
                      true={1}
                      false={0}
                      value={item.block}
                    />
                    <p className="geo-block-meet-list-text overflow-ellipsis">
                      {item?.country_name}
                    </p>
                  </Space>
                </Col>
                <Col span={8}>
                  {item.block === 1 ? (
                    <Tag
                      style={{
                        borderRadius: 15,
                        width: 70,
                        textAlign: "center",
                      }}
                      color="#f50"
                    >
                      Blocked
                    </Tag>
                  ) : null}
                </Col>
              </Row>
            </div>
          );
        })
      ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      )}
    </section>
  );
};

export default GeoBlockingContent;
