import { FC, useCallback, useEffect, useState } from "react";
import "./index.less";
import { Col, Button, Layout, Row, Spin, notification, Empty } from "antd";
import CustomRadio from "@/components/customRadio";
import { useNavigate } from "react-router-dom";
import meetingAPI from "@/api/apis/meetings";
import useUILoading from "@/hooks/useLoading";
const Meeting: FC = () => {
  const navigate = useNavigate();
  const [loading, open, close] = useUILoading();
  const [meetingList, setMeetingList] = useState([]);
  const [notificationApi, contextHolder] = notification.useNotification();

  const getMeetingList = useCallback(async () => {
    open();
    const res = await meetingAPI.mettingList();
    close();
    if (res) {
      setMeetingList(res);
    }
  }, [open, close]);
  useEffect(() => {
    getMeetingList();
  }, [getMeetingList]);
  const handeSwitchMeeting = useCallback(
    (is_live: 1 | 0, rowData: any) => {
      const _data = [{ ...rowData, is_live }];
      const msg =
        is_live === 1 ? "Enabled successfully" : "Disabled successfully";
      open();
      meetingAPI
        .updateMeeting(_data)
        .then((res) => {
          if (res !== null) {
            getMeetingList();
            notificationApi.success({ message: msg });
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(close);
    },
    [open, close, getMeetingList, notificationApi]
  );

  return (
    <Layout>
      {contextHolder}
      <Spin spinning={loading}>
        <h2 className="las-pages-title">
          Meetings <span>management</span>
        </h2>
        <div className="row-btm20">
          <Button
            onClick={() => {
              navigate("/admin/provisioning/meeting/manage-meetings");
            }}
            type="primary"
          >
            Manage Meetings
          </Button>
        </div>
        <main className="page-container metting-pages-container">
          <h3>Check to enable live streaming</h3>
          {meetingList?.length ? (
            meetingList.map((item: any, idx) => {
              return (
                <div key={idx} className="row-btm20">
                  <Row>
                    <Col flex={1}>
                      <CustomRadio
                        text={item.name}
                        true={1}
                        false={0}
                        value={item.is_live}
                        onChange={(e) => {
                          handeSwitchMeeting(e, item);
                        }}
                      />
                    </Col>
                  </Row>
                </div>
              );
            })
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </main>
      </Spin>
    </Layout>
  );
};

export default Meeting;
