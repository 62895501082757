import excelAPI from "@/api/apis/excel";
import subscriberAPI from "@/api/apis/subscriber";
import Template from "@/common/template";
import { filterEmptyProperties } from "@/common/utils/untils";
import { handleTimeFormat } from "@/common/utils/untilsX";
import SelectorDataFromTo from "@/components/SelectorDataFromTo";
import TableHeaderQueryData from "@/components/TableHeaderQueryData";
import TableHeaderQueryDataByDatePicker from "@/components/TableHeaderQueryDataByDatePicker";
import useEventId from "@/hooks/useEventId";
import { FC, SetStateAction, useCallback, useMemo, useState } from "react";
import { exportTableToPDF } from "./pdfHtml";

const CustomerServiceTerminated: FC = () => {
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const [activationTime, setActivationTime] = useState<string>("");
  const [expireTime, setExpireDate] = useState<string>("");
  const [accountNumber, setAccountNumber] = useState<string>("");
  const [requestDate, setRequestDate] = useState<string>("");
  // const [activationDates, setActivationDates] = useState<string>("");
  const [terminationDates, setTerminationDates] = useState<string>("");
  const [billingCycle, setBillingCycle] = useState<string>("");
  // const [terminationInitiated, setTerminationInitiated] = useState<string>("");
  const [terminationBy, setTerminationBy] = useState<string>("");
  const templateId = useEventId();
  const queryData = (data: any) => {
    const _data = {
      ...data,
      status: "T",
    };
    return subscriberAPI.getCustomerServiceValid(_data);
  };
  const change = useCallback(
    (e: {
      startTime: SetStateAction<string>;
      endTime: SetStateAction<string>;
    }) => {
      setStartTime(e.startTime);
      setEndTime(e.endTime);
    },
    []
  );
  const columns = [
    {
      key: "account_number",
      title: (
        <TableHeaderQueryData
          title="Account Number"
          getData={setAccountNumber}
        />
      ),
      dataIndex: "account_number",
    },
    {
      key: "request_date",
      title: (
        <TableHeaderQueryDataByDatePicker
          title="Request Date"
          getData={setRequestDate}
        />
      ),
      dataIndex: "request_date",
      render: (e: string) => handleTimeFormat(e, "MMM DD, YYYY hh:mm A"),
    },
    {
      key: "start_date",
      title: (
        <TableHeaderQueryDataByDatePicker
          title="Activation Date"
          getData={setActivationTime}
        />
      ),
      dataIndex: "start_date",
      render: (e: string) => handleTimeFormat(e, "MMM DD, YYYY hh:mm A"),
    },
    {
      key: "end_date",
      title: (
        <TableHeaderQueryDataByDatePicker
          title="End Date"
          getData={setExpireDate}
        />
      ),
      dataIndex: "end_date",
      render: (e: string) => handleTimeFormat(e, "MMM DD, YYYY"),
    },
    {
      key: "billing_cycle",
      title: (
        <TableHeaderQueryData title="Billing Cycle" getData={setBillingCycle} />
      ),
      dataIndex: "billing_cycle",

      render: (e: string) => e || "-",
    },
    {
      key: "termination_initiated_date",
      title: (
        <TableHeaderQueryDataByDatePicker
          title="Termination Initiated"
          getData={setTerminationDates}
        />
      ),
      dataIndex: "termination_initiated_date",
      render: (e: string) => handleTimeFormat(e, "MMM DD, YYYY hh:mm A"),
    },
    {
      key: "termination_by_name",
      title: (
        <TableHeaderQueryData
          title="Termination By"
          getData={setTerminationBy}
        />
      ),
      dataIndex: "termination_by_name",
    },
  ];
  const queryParams = useMemo(() => {
    return filterEmptyProperties({
      account_number: accountNumber,
      request_date: requestDate,
      // activation_date: activationDates,
      termination_initiated_date: terminationDates,
      billing_cycle: billingCycle,
      // termination_reason: terminationInitiated,
      termination_initiated_by: terminationBy,
      active_date: activationTime,
      expire_date: expireTime,
      from: startTime,
      to: endTime,
      status: "T",
      action: "terminated",
    });
  }, [
    accountNumber,
    requestDate,
    // activationDates,
    terminationDates,
    billingCycle,
    // terminationInitiated,
    terminationBy,
    startTime,
    endTime,
    activationTime,
    expireTime,
  ]);
  const exportEvent = useCallback((data: any) => {
    return excelAPI.subscriberTerminatedExportExcel(data);
  }, []);
  const exportEventPdf = useCallback(async (data: any) => {
    const res = await subscriberAPI.getCustomerServiceValid({
      ...data,
      operate_log: 1,
    });
    const timeStr = `${
      handleTimeFormat(data.from, "DD MMM YYYY", false) || res.min_time
    } - ${handleTimeFormat(data.to, "DD MMM YYYY", false) || res.max_time}`;

    return exportTableToPDF({ ...res, heading4: timeStr });
  }, []);

  return (
    <section>
      <div className="audit-logs-box">
        <h2 className="las-pages-title">
          Customer Service <span>Terminated</span>
        </h2>
        <SelectorDataFromTo onChange={change} />
        <div className="bg-white" style={{ marginTop: 15 }}>
          <Template
            id={templateId}
            title={""}
            exportConfig={{
              queryApi: exportEvent,
              fileName: "customer_service_terminated",
              pdfQueryApi: exportEventPdf,
            }}
            // showFooter={false}
            queryData={queryData}
            rowKey={"id"}
            config={columns}
            scroll={{ x: 1600 }}
            moreParams={queryParams}
          />
        </div>
      </div>
    </section>
  );
};

export default CustomerServiceTerminated;
