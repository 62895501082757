import IconFont from "@/common/IconFont";
import { ICallback } from "@/common/interface";
import { Col, DatePicker, Form, Row } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import "./index.less";
interface ISelectorDataFromTo {
  onChange?: ICallback<any>;
  label1?: string;
  label2?: string;
}
const ValidDataTime: FC<ISelectorDataFromTo> = ({
  onChange,
  label1,
  label2,
}) => {
  const { current: change } = useRef(onChange);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  useEffect(() => {
    if (startTime || endTime) {
      change?.({
        startTime,
        endTime,
      });
    } else {
      change?.({
        startTime: "",
        endTime: "",
      });
    }
  }, [startTime, endTime, change]);
  const handleSetStartTime = useCallback((e: any) => {
    setStartTime(e ? dayjs(e).format("YYYY-MM-DD") : "");
  }, []);
  const handleSetEndTime = useCallback((e: any) => {
    setEndTime(e ? dayjs(e).format("YYYY-MM-DD") : "");
  }, []);


  // 禁用结束日期在开始日期之前的日期
  const disabledEndDate = useCallback(
    (current: Dayjs | null) => {
      if (!current) {
        return false;
      }
      return startTime ? current.isBefore(dayjs(startTime), "day") : false;
    },
    [startTime]
  );

  // 禁用开始日期在结束日期之后的日期
  const disabledStartDate = useCallback(
    (current: Dayjs | null) => {
      if (!current) {
        return false;
      }
      return endTime ? current.isAfter(dayjs(endTime), "day") : false;
    },
    [endTime]
  );
  return (
    <Row gutter={16}>
      <Col flex={1}>
        <Form.Item label={label1 || "From"}>
          <DatePicker
            style={{ width: "100%" }}
            placeholder=""
            onChange={handleSetStartTime}
            inputReadOnly={true}
            className="custom-datepicker"
            suffixIcon={
              <IconFont style={{ fontSize: 22 }} type="icon-calendar-alt" />
            }
            disabledDate={disabledStartDate}
          />
        </Form.Item>
      </Col>
      <Col flex={1}>
        <Form.Item label={label2 || "To"}>
          <DatePicker
            style={{ width: "100%" }}
            placeholder=""
            onChange={handleSetEndTime}
            inputReadOnly={true}
            className="custom-datepicker"
            suffixIcon={
              <IconFont style={{ fontSize: 22 }} type="icon-calendar-alt" />
            }
            disabledDate={disabledEndDate}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default ValidDataTime;
